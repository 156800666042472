export interface AlignmentOption {
    value: 'start' | 'center' | 'end'
    icon: string
}

export interface TextAlignmentOption {
    value: 'left' | 'center' | 'right'
    icon: string
}

export const ALIGNMENT_OPTIONS: AlignmentOption[] = [
    { value: 'start', icon: 'AlignStart' },
    { value: 'center', icon: 'AlignCenter' },
    { value: 'end', icon: 'AlignEnd' },
] as const

export const TEXT_ALIGNMENT_OPTIONS: TextAlignmentOption[] = [
    { value: 'left', icon: 'AlignStart' },
    { value: 'center', icon: 'AlignCenter' },
    { value: 'right', icon: 'AlignEnd' },
] as const
