<template>
    <div class="handwriting-input-item mb-3" @drop="(e) => e.preventDefault()">
        <div
            :id="`style_picker_${item.id}_${index}`"
            class="header p-0"
            @mouseover="hovered = true"
            @mouseleave="hovered = false"
        >
            <HandwritingStylePicker
                :key="`style_picker_${item.id}_${index}`"
                :style-selector="styleSelector"
                :index="index"
                :active="(focused || hovered) && index > -1"
                :font="font"
                @change="setLineStyle"
                @duplicate="duplicateItem"
                @delete="removeItem"
            />
        </div>
        <VueEditor
            :ref="`widget_${item.id}_${index}`"
            :key="`editor_${item.id}_${index}`"
            v-model="handwritingInput"
            :editor-toolbar="customToolbar"
            :editor-options="editorSettings"
            :placeholder="placeholder"
            @text-change="onChangeHandwritingText"
            @focus="onFocusEditor"
            @blur="focused = false"
        ></VueEditor>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { VueEditor, VueEditorData } from 'vue2-editor'
import uniqueId from 'lodash/uniqueId'
import HandwritingStylePicker from './HandwritingStylePicker.vue'
import HandWritingInputItemModel from '../store/models/HandWritingInputItem'
import { PartialStyle, Data, HandWritingInputItem } from '../types/handwritinginputitem'

export default defineComponent({
    name: 'HandwritingInputItem',
    components: {
        HandwritingStylePicker,
        VueEditor,
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
        },
        font: {
            type: String,
            required: true,
        },
    },
    data(): Data {
        return {
            uniqueId: '',
            focused: false,
            itemData: { ...this.item } as HandWritingInputItem,
            customToolbar: [[], [], []],
            hovered: false,
        }
    },
    computed: {
        placeholder(): string {
            return this.index > -1 ? 'Letters or words to copy (optional)' : '+Add New Item'
        },
        handwritingInput: {
            get(): string {
                return this.itemData.subtitle
            },
            set(value: string) {
                this.itemData.subtitle = value.replace(/(<([^>]+)>)/gi, '').trim()
                this.updateData()
            },
        },
        styleSelector(): string {
            if (!this.focused && this.partialStyles.length === 1) {
                return this.partialStyles[0]
            } else if (this.focused && this.hasSelection) {
                return this.selectedStyle
            }

            return this.itemData.line_style
        },
        partialStyles(): string[] {
            let styles: string[] = []
            this.itemData.partials.forEach((p: PartialStyle) => {
                if (!styles.includes(p.style)) {
                    styles.push(p.style)
                }
            })
            return styles
        },
        selectedStyle(): string {
            let quillRef = this.editorInputElement?.quill
            let editorSelection = quillRef?.selection

            if (this.hasSelection) {
                const savedRange = editorSelection.savedRange
                const partial = this.itemData.partials.find(
                    (p: PartialStyle) => p.start <= savedRange.index && savedRange.index + savedRange.length <= p.end,
                )

                if (partial) {
                    return partial.style
                }
            }

            return ''
        },
        hasSelection(): boolean {
            let quillRef = this.editorInputElement?.quill
            let editorSelection = quillRef?.selection

            return editorSelection && editorSelection.savedRange.index !== undefined && editorSelection.savedRange.length
        },
        editorInputElement(): VueEditorData {
            return this.$refs[`widget_${this.item.id}_${this.index}`] as unknown as VueEditorData
        },
        editorSettings(): any {
            return {
                formats: ['script'],
                modules: {
                    toolbar: {
                        container: `#style_picker_${this.item.id}_${this.index}`,
                    },
                    keyboard: {
                        bindings: {
                            enter: {
                                key: 13,
                                handler: () => {
                                    this.moveFocus()
                                    return false
                                },
                            },
                            shiftEnter: {
                                key: 13,
                                shiftKey: true,
                                handler: () => {
                                    const moveToPrevious = true
                                    this.moveFocus(moveToPrevious)
                                    return false
                                },
                            },
                            tab: {
                                key: 9,
                                handler: () => {
                                    this.moveFocus()
                                    return false
                                },
                            },
                            shiftTab: {
                                key: 9,
                                shiftKey: true,
                                handler: () => {
                                    const moveToPrevious = true
                                    this.moveFocus(moveToPrevious)
                                    return false
                                },
                            },
                        },
                    },
                },
            }
        },
    },
    created() {
        this.uniqueId = uniqueId('handwriting_item_') as string
    },
    mounted() {
        this.$nextTick(() => {
            if (this.index > -1) {
                this.editorInputElement?.quill.root.focus()
                this.focused = true
            }
        })
    },
    methods: {
        onFocusEditor() {
            this.focused = true
            if (this.index === -1) {
                this.$emit('add', this.itemData)
                this.itemData = new HandWritingInputItemModel()
            }
        },
        removeItem(index: number) {
            this.$emit('delete', index)
        },
        duplicateItem(index: number) {
            this.$emit('duplicate', index)
        },
        setLineStyle(style: string) {
            let quillRef = this.editorInputElement?.quill
            let editorSelection = quillRef?.selection
            if (quillRef && this.focused) {
                quillRef.focus()
            }

            if (editorSelection?.savedRange.index !== undefined && editorSelection?.savedRange.length) {
                const range = editorSelection.savedRange
                this.setTraceStyleForRange({
                    start: range.index,
                    end: range.index + range.length,
                    style,
                })
            } else if (!this.focused) {
                this.itemData.partials = [
                    {
                        start: 0,
                        end: this.itemData.subtitle.length,
                        style,
                    },
                ]
            }

            this.itemData.line_style = style
            this.updateData()
        },
        onChangeHandwritingText({ ops }: { ops: any[] }) {
            if (
                ops.length === 1 &&
                ops[0].insert === this.itemData.subtitle &&
                this.itemData.subtitle.length > 1 &&
                this.itemData.partials.length
            ) {
                return
            }
            if ((ops.length === 1 && ops[0].insert) || (ops.length === 1 && ops[0].delete)) {
                ops.unshift({ retain: 0 })
            }

            if (ops.length > 1 && this.itemData.partials) {
                const [{ retain }, op] = ops
                this.focused = true
                if (op.insert) {
                    const targetPartial = this.itemData.partials.find(
                        (p: PartialStyle) => p.start <= retain && retain <= p.end && p.style === this.itemData.line_style,
                    )

                    const insertCount = op.insert.length
                    if (targetPartial && insertCount) {
                        this.updateAfterPartials(targetPartial, insertCount)
                        targetPartial.end += insertCount
                    } else {
                        const partial = this.itemData.partials.find((p: PartialStyle) => p.start <= retain && retain < p.end)
                        if (partial) {
                            this.setTraceStyleForRange({
                                start: retain,
                                end: retain + insertCount,
                                style: partial.style,
                                insert: insertCount,
                                retain,
                            })
                        } else {
                            this.itemData.partials.push({
                                start: retain,
                                end: retain + insertCount,
                                style: this.itemData.line_style,
                            })
                            this.sortPartials()
                        }
                    }
                } else if (op.delete) {
                    this.onDeleteText({
                        retain,
                        deleteCount: op.delete,
                    })
                }

                this.removeDuplications()
                this.updateData()
            }
        },
        removeDuplications() {
            let newPartials: PartialStyle[] = [],
                last: PartialStyle | null = null
            this.itemData.partials.forEach(function (r: PartialStyle) {
                if (!last || r.start > last.end || r.style !== last.style) newPartials.push((last = r))
                else if (r.end > last.end) {
                    last.end = r.end
                    last.style = r.style
                } else if (r.start < last.end) {
                    r.start = last.end
                }
            })

            this.itemData.partials = newPartials
        },
        updateData() {
            this.$emit('change', { data: this.itemData, index: this.index })
            if (this.index === -1) {
                this.$emit('add', this.itemData)
            }
        },
        onDeleteText({ retain, deleteCount }: { retain: number; deleteCount: number }) {
            const targetPartial = this.itemData.partials.find((p: PartialStyle) => p.start <= retain && retain < p.end)

            if (targetPartial && deleteCount) {
                let deletedCount = 0
                deletedCount = Math.min(targetPartial.end - retain, deleteCount)
                targetPartial.end -= deletedCount
                this.updateAfterPartials(targetPartial, -deletedCount)

                if (targetPartial.end <= targetPartial.start) {
                    this.removePartial(targetPartial)
                }
                deleteCount -= deletedCount
                if (deleteCount > 0) {
                    this.onDeleteText({
                        retain: retain + deletedCount,
                        deleteCount,
                    })
                }
            }
        },
        removePartial(partial: PartialStyle) {
            const rmIndex = this.itemData.partials.findIndex(
                (p: PartialStyle) => p.start === partial.start && p.end === partial.end,
            )
            this.itemData.partials.splice(rmIndex, 1)
        },
        sortPartials() {
            this.itemData.partials.sort((p1: PartialStyle, p2: PartialStyle) => p1.start - p2.start)
        },
        updateAfterPartials(partial: PartialStyle, count: number) {
            if (!partial || !count) return

            this.getAfterPartials(partial).forEach((p: PartialStyle) => {
                p.start += count
                p.end += count
            })
            this.sortPartials()
        },
        setTraceStyleForRange({
            start,
            end,
            style,
            retain,
            insert,
        }: {
            start: number
            end: number
            style: string
            retain?: number
            insert?: number
        }) {
            if (!this.itemData.partials) return

            if (insert) {
                this.itemData.partials.forEach((p: PartialStyle) => {
                    if (retain && p.start > retain) {
                        p.start += insert
                    }
                    if (retain && p.end > retain) {
                        p.end += insert
                    }
                })
                this.sortPartials()
            }

            this.itemData.partials = this.itemData.partials.flatMap((p: PartialStyle): PartialStyle[] => {
                if (p.end <= start || end <= p.start) {
                    return [p]
                } else if (p.start <= start && p.end <= end && start != p.end) {
                    return p.start === start
                        ? [{ start, end, style }]
                        : [
                              { start: p.start, end: start, style: p.style },
                              { start, end, style },
                          ]
                } else if (start <= p.start && end <= p.end) {
                    return [
                        { start, end, style },
                        { start: end, end: p.end, style: p.style },
                    ]
                } else if (p.start < start && end < p.end) {
                    return [
                        { start: p.start, end: start, style: p.style },
                        { start, end, style },
                        { start: end, end: p.end, style: p.style },
                    ]
                }

                return []
            })

            this.removeDuplications()
            this.updateData()
        },
        getAfterPartials(partial: PartialStyle): PartialStyle[] {
            const partialIndex = this.itemData.partials.indexOf(partial)
            let afterPartials: PartialStyle[] = []
            if (partialIndex !== -1 && partialIndex !== this.itemData.partials.length - 1) {
                afterPartials = this.itemData.partials.slice(partialIndex + 1)
            }

            return afterPartials
        },
        moveFocus(shiftKey: boolean = false) {
            this.$emit('move-focus', { index: this.index, shiftKey })
        },
    },
})
</script>

<style lang="scss" scoped>
.handwriting-input-item {
    .header {
        border: 1px solid #ccc;
        border-bottom: none;
        border-radius: 0.3rem 0.25rem 0 0;
        background-color: rgb(247, 247, 247);
        padding: 0 !important;
    }
}
</style>

<style lang="scss">
.handwriting-input-item {
    .ql-container {
        .ql-snow {
            border-top: none !important;
        }
    }
}
</style>
