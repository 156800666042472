import { defineComponent } from 'vue'

interface Data {
    hovered: Record<number, boolean>
    active: Record<number, boolean>
    subOptionHovered: Record<number, boolean>
    subOptionActive: Record<number, boolean>
}

export default defineComponent({
    name: 'WithItemEventState',
    data(): Data {
        return {
            hovered: {},
            active: {},
            subOptionHovered: {},
            subOptionActive: {},
        }
    },
    methods: {
        updateState(stateKey: keyof Data, index: number, value: boolean) {
            this[stateKey] = { ...this[stateKey], [index]: value }
        },
        deleteButtonClass(index: number): string {
            return this.hovered[index] || this.active[index] ? 'visible' : 'hidden'
        },
    },
})
