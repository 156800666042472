<template>
    <div>
        <b-form-checkbox v-model="includeWordBank" class="f-14" name="checkbox-9" :disabled="disabled">
            Include in Word Bank
        </b-form-checkbox>
        <div
            v-if="includeWordBank"
            class="wordbanks-wrapper pl-4 mt-2 d-flex flex-wrap align-items-center"
            :class="{ disabled: disabled }"
        >
            <span
                v-for="wordbank in allWordbanks"
                :key="wordbank.id"
                class="p-1 mr-1 pointer"
                :class="wordbank.data.id === localData.wordbank_id ? 'selected rounded' : ''"
            >
                <p
                    class="p-2 m-0"
                    :style="{
                        backgroundColor: wordbank.data.color,
                    }"
                    @click="switchWordBank(wordbank.data.id)"
                />
            </span>
            <span class="add-new p-1 d-flex pointer" @click="addNewWordbank()">
                <b-icon icon="plus" />
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { WordScrambleData } from '../types/word-scramble'
import { WordBankData } from '../types/wordbank'
import { FillInTheBlankData, FillInTheBlankWordPartials } from '../types/fill-in-the-blank'
import { Item } from '../types/item'

interface Data {
    isUpdating: boolean
}
export default defineComponent({
    name: 'IncludeWordbank',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data(): Data {
        return {
            isUpdating: false,
        }
    },
    computed: {
        allWordbanks(): Item<WordBankData>[] {
            return this.$store.getters['document/allWordbanks']
        },
        documentItems(): Item<WordScrambleData | FillInTheBlankData>[] {
            return this.$store.getters['document/documentItems']
        },
        localData: {
            get(): WordScrambleData | FillInTheBlankData {
                return this.documentItems[this.index].data
            },
            set(value: WordScrambleData | FillInTheBlankData) {
                this.localData = value
            },
        },
        itemId(): string {
            return this.documentItems[this.index].id
        },
        hasWordBanks(): boolean {
            return this.allWordbanks?.length > 0
        },
        isWordScrambleData(): boolean {
            return (this.localData as WordScrambleData).terms !== undefined
        },
        isFillInTheBlankData(): boolean {
            return (this.localData as FillInTheBlankData).words !== undefined
        },
        disabled(): boolean {
            if (!this.localData) return true

            if (this.isWordScrambleData) {
                const wordScramble = this.localData as WordScrambleData
                return (
                    !wordScramble.terms ||
                    !wordScramble.terms.length ||
                    (wordScramble.terms.length === 1 && (!wordScramble.terms[0] || !wordScramble.terms[0].length))
                )
            }

            const fillInTheBlank = this.localData as FillInTheBlankData

            if (!fillInTheBlank.words) return true

            return !fillInTheBlank.words.some(
                (item: string | FillInTheBlankWordPartials) => typeof item !== 'string' && item.partials.length,
            )
        },
        includeWordBank: {
            get() {
                if (!this.hasWordBanks) {
                    this.handleEmptyWordbank()
                }

                return !!this.localData.include_wordbank
            },
            async set(value: boolean) {
                this.localData.include_wordbank = value

                if (!value) {
                    await this.removeFromWordbank()
                    this.updateData()
                    return
                }

                await this.updateWordbank()
                this.updateData()
            },
        },
        words() {
            return this.isWordScrambleData
                ? (this.localData as WordScrambleData).terms
                : (this.localData as FillInTheBlankData).words
        },
    },
    watch: {
        allWordbanks: {
            deep: true,
            handler() {
                if (this.allWordbanks?.length) return

                this.handleEmptyWordbank()
            },
        },
        words: {
            handler() {
                const wordbank = this.allWordbanks?.find(
                    (wordbank) => wordbank.data.id === this.localData.wordbank_id,
                ) as Item<WordBankData>

                if (!wordbank || !this.includeWordBank) return

                const words = this.isWordScrambleData ? (this.localData as WordScrambleData).terms : this.getBlankWords()

                if (typeof words === 'undefined' || !words.length) return

                wordbank.data.removeAll(this.itemId)
                wordbank.data.addWords(words, this.itemId)
            },
            deep: true,
        },
    },
    methods: {
        async addNewWordbank() {
            const words = this.isWordScrambleData
                ? (this.localData as WordScrambleData).terms
                : (this.localData as FillInTheBlankData).words
                      ?.filter((word: string | FillInTheBlankWordPartials) => typeof word !== 'string' && word.blank)
                      .map((word) => (word as FillInTheBlankWordPartials).value)

            await this.$store.dispatch('document/addNewWordbank', {
                index: this.index,
                words: words,
            })
        },
        async updateWordbank() {
            if (!this.localData.include_wordbank) return
            try {
                this.isUpdating = true

                const hasWordBankId = !!this.localData.wordbank_id
                const firstWordBankId = this.hasWordBanks && this.allWordbanks ? this.allWordbanks[0].data?.id : null

                if (!hasWordBankId && !firstWordBankId) {
                    await this.addNewWordbank()
                    return
                }

                const target_id = hasWordBankId ? this.localData.wordbank_id : firstWordBankId

                await this.$store.dispatch('document/updateWordbank', {
                    index: this.index,
                    words: this.isWordScrambleData ? (this.localData as WordScrambleData).terms : this.getBlankWords(),
                    target_id,
                })

                this.updateData()
            } finally {
                this.isUpdating = false
            }
        },
        async switchWordBank(wordbank_id: string) {
            if (this.localData.wordbank_id === wordbank_id) return

            await this.removeFromWordbank()
            await this.$store.dispatch('document/updateWordbank', {
                index: this.index,
                words: this.isWordScrambleData ? (this.localData as WordScrambleData).terms : this.getBlankWords(),
                target_id: wordbank_id,
            })
        },
        async removeFromWordbank() {
            await this.$store.dispatch('document/removeFromWordbank', {
                wordbank_id: this.localData.wordbank_id,
                index: this.index,
            })
        },
        handleEmptyWordbank() {
            this.localData.include_wordbank = false
            this.localData.wordbank_id = undefined
            this.updateData()
        },
        updateData() {
            this.$emit('updateData', this.localData)
        },
        getBlankWords(): string[] {
            if (
                this.isWordScrambleData ||
                (this.isFillInTheBlankData && !(this.localData as FillInTheBlankData).words?.length)
            )
                return []

            let wordbank_words: string[] = []

            const fillInTheBlank = this.localData as FillInTheBlankData

            const partialsData = fillInTheBlank.words?.filter(
                (word: string | FillInTheBlankWordPartials) => typeof word !== 'string' && word.partials.length,
            )

            partialsData?.forEach((word) => {
                if ((word as FillInTheBlankWordPartials).partials && (word as FillInTheBlankWordPartials).value) {
                    for (let partial of (word as FillInTheBlankWordPartials).partials) {
                        let partialword = (word as FillInTheBlankWordPartials).value.substring(partial.start, partial.end)
                        partialword = partialword.trim()
                        if (partialword) {
                            wordbank_words.push(partialword)
                        }
                    }
                } else {
                    wordbank_words.push((word as FillInTheBlankWordPartials).value)
                }
            })
            return wordbank_words
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';
.wordbanks-wrapper {
    .add-new {
        border-radius: 50%;
        background: $gray-500;
        color: white;
    }
    span {
        p {
            border-radius: 50%;
        }
        &.selected {
            outline: 1px solid #212121;
        }
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>
