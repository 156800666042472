<template>
    <div>
        <OptionButtonSelector
            :active="alignment"
            :options="options"
            :size="size"
            variant="primary"
            class="form-group"
            @change="changeAlignment"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import OptionButtonSelector from '../stories/components/buttons/OptionButtonSelector.vue'
import type ButtonSelectorOption from '../stories/types/ButtonSelectorOption'

export default defineComponent({
    name: 'Alignment',
    components: {
        OptionButtonSelector,
    },
    props: {
        value: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: 'sm',
        },
        options: {
            type: Array as PropType<ButtonSelectorOption[]>,
            default: () => [],
        },
    },
    data() {
        return {
            alignment: this.value,
        }
    },
    methods: {
        handleInput(): void {
            this.$emit('input', this.alignment)
        },
        changeAlignment(value: string): void {
            this.alignment = value
            this.handleInput()
        },
    },
})
</script>
