<template>
    <div class="d-flex style" @mouseenter="hovered = true" @mouseleave="hovered = false">
        <div
            :class="[behaviourClass]"
            class="f-11 p-0 w-100 h-100 rounded ql-formats cursor-pointer mr-3"
            tabindex="-1"
            @click="$emit('click', name)"
        >
            <component v-b-tooltip.hover.noninteractive :title="title" :is="iconName" class="icon rounded border-0" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import IconDirection from '../stories/components/icons/IconDirection.vue'
import IconDotted from '../stories/components/icons/IconDotted.vue'
import IconGuided from '../stories/components/icons/IconGuided.vue'
import IconOutline from '../stories/components/icons/IconOutline.vue'
import IconSolid from '../stories/components/icons/IconSolid.vue'

export default defineComponent({
    name: 'HandwritingStyle',
    components: {
        IconDirection,
        IconDotted,
        IconGuided,
        IconOutline,
        IconSolid,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hovered: false,
        }
    },
    computed: {
        iconName() {
            return `Icon${this.icon.charAt(0).toUpperCase() + this.icon.slice(1)}`
        },
        behaviourClass() {
            if (this.active) return 'text-primary'
            if (this.hovered) return 'text-primary-600'
            return 'text-secondary'
        },
    },
})
</script>

<style scoped>
.style {
    .icon {
        height: 30px;
        width: 30px;
    }
}
</style>
