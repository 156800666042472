<template>
    <div v-click-outside="handleClickOutside" class="dropdown">
        <button
            id="dropdownMenuButton"
            class="custom-select custom-select-sm d-block"
            type="button"
            @click="handleButtonClick"
        >
            <span v-if="selectedOption.icon" class="selected-label">
                <span class="ml-6">{{ parentNode.label }}</span>
                <IconLoader v-if="selectedOption.icon" class="mr-6" :icon="selectedOption.icon" size="sm" />
            </span>
            <span v-else class="selected-label">
                <span>{{ parentNode.label }}</span>
                <span v-if="parentNode.subOptions">{{ selectedSubOptionLabel }}</span>
            </span>
        </button>
        <ul ref="dropdownMenu" class="dropdown-menu" :class="{ show: isOpen }" aria-labelledby="dropdownMenuButton">
            <li
                v-for="(option, index) in options"
                :id="`option-${index}-${isSecondary ? 'secondary' : 'primary'}`"
                :key="option.label"
                class="dropdown-item px-0"
                :class="{ 'first-item': index === 0 || (index < 4 && dropdownMenuTop < 70) }"
                @mouseenter="checkSubMenuPosition"
                @mouseleave="checkSubMenuPosition"
            >
                <div
                    class="d-flex align-items-center"
                    :class="{ clickable: !option.subOptions }"
                    @click="!option.subOptions && selectOption(option)"
                >
                    <div class="col-3 d-flex justify-content-center">
                        <IconLoader v-if="option.icon" :icon="option.icon" size="sm" />
                        <span v-else class="mr-2" v-html="option.prefix"></span>
                    </div>
                    <div class="col-6 d-flex align-items-center pl-0">
                        <span class="mr-2">{{ option.label }}</span>
                        <PremiumMarker
                            v-if="option.isPremium"
                            :container="`option-${index}-${isSecondary ? 'secondary' : 'primary'}`"
                            :name="`number-format|${option.label}`"
                            feature="numbering"
                        />
                    </div>
                    <div class="col-3 d-flex justify-content-end pl-0">
                        <IconLoader v-if="option.subOptions" icon="CaretRightFill" size="xs" />
                    </div>
                </div>

                <ul
                    v-if="option.subOptions"
                    class="dropdown-menu dropdown-submenu align-items-center"
                    :style="hasPremiumOption(option.subOptions) ? 'overflow: visible' : ''"
                >
                    <li
                        v-for="(subOption, subOptionIndex) in option.subOptions"
                        :id="`subOption-${index}-${subOptionIndex}-${isSecondary ? 'secondary' : 'primary'}`"
                        :key="subOption.value"
                        class="dropdown-item"
                        @click.stop="selectSubOption(subOption, option)"
                    >
                        <div class="d-flex align-items-center">
                            <IconLoader v-if="subOption.icon" :icon="subOption.icon" size="sm" />
                            <span v-else v-html="subOption.label" />
                            <PremiumMarker
                                v-if="subOption.isPremium"
                                :container="`subOption-${index}-${subOptionIndex}-${isSecondary ? 'secondary' : 'primary'}`"
                                :name="`number-format|${subOption.label}`"
                                feature="numbering"
                                class="ml-2"
                                @click="isOpen = false"
                            />
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import IconLoader from '../icons/IconLoader.vue'
import PremiumMarker from '../../../widgets/premium-marker.vue'
import ClickOutside from '../../../directives/click-outside'
import { FormatOption, FormatSubOption } from '../../types/FormatOption'

interface Data {
    isOpen: boolean
    selectedOption: FormatOption | FormatSubOption
    parentNode: FormatOption
    dropdownMenuTop: number
}

export default defineComponent({
    name: 'NestedSelect',
    components: {
        IconLoader,
        PremiumMarker,
    },
    directives: {
        'click-outside': ClickOutside,
    },
    props: {
        options: {
            type: Array as PropType<FormatOption[]>,
            required: true,
        },
        defaultOption: {
            type: Object as PropType<FormatOption | FormatSubOption>,
            required: true,
        },
        isSecondary: {
            type: Boolean,
            default: false,
        },
    },
    data(): Data {
        return {
            isOpen: false,
            selectedOption: this.defaultOption,
            parentNode: {} as FormatOption,
            dropdownMenuTop: 0,
        }
    },
    computed: {
        dropdownMenu() {
            return this.$refs.dropdownMenu as HTMLElement
        },
        selectedSubOptionLabel(): string | undefined {
            this.findParentLabel()
            return this.selectedOption ? this.selectedOption.label : this.defaultOption.label
        },
    },
    watch: {
        defaultOption: function (newVal) {
            this.selectedOption = newVal
            this.findParentLabel()
        },
    },
    created() {
        this.findParentLabel()
        if (!this.parentNode.label) {
            this.parentNode = this.defaultOption as FormatOption
        }
    },
    methods: {
        handleClickOutside() {
            if (!this.isOpen) {
                return
            }

            this.isOpen = false
        },
        handleButtonClick() {
            this.isOpen = !this.isOpen
        },
        selectSubOption(option: FormatSubOption, parentOption: FormatOption) {
            this.selectedOption = option
            this.isOpen = false
            this.parentNode = parentOption
            this.$emit('change', option.value)
        },
        findParentLabel() {
            // First, try to find a parent option with matching subOption
            const parentOption = this.options.find((option) =>
                option.subOptions?.some((subOption) => subOption.value === this.selectedOption.value),
            )

            if (parentOption) {
                this.parentNode = parentOption
            } else {
                // If no parent with subOption found, look for a direct match in options
                this.parentNode = this.options.find((option) => option.value === this.selectedOption.value) as FormatOption
            }
        },
        selectOption(option: FormatOption) {
            this.selectedOption = option
            this.parentNode = option
            if (!option.subOptions) {
                this.isOpen = false
            }
            this.$emit('change', option.value)
        },
        checkSubMenuPosition() {
            const rect = (this.$refs.dropdownMenu as HTMLElement).getBoundingClientRect()
            this.dropdownMenuTop = rect.top
        },
        hasPremiumOption(subOptions: FormatSubOption[]) {
            return subOptions.some((option) => option.isPremium)
        },
    },
})
</script>

<style scoped>
.dropdown {
    position: relative;
}
.dropdown-menu {
    position: absolute;
    top: -183px;
    left: 160px;
    min-width: 70px;
    z-index: 1000;
    cursor: pointer;
    max-height: 100vh;
}
.dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
}
.dropdown-menu .dropdown-submenu {
    position: absolute;
    left: 100%;
    bottom: 0;
    top: auto;
    z-index: 1000;
    min-width: 70px;
    max-height: 100vh;
    overflow-y: auto;
}
.dropdown-item:hover:not(.dropdown-submenu) {
    background-color: rgba(var(--primary-rgb), 0.2);
    color: var(--primary);
}
.dropdown-item:active:not(.dropdown-submenu) {
    background-color: rgba(var(--primary-rgb), 0.2) !important;
    color: var(--primary) !important;
}

.custom-select {
    width: 100% !important;
}

.selected-label {
    display: flex !important;
    align-items: center;
    justify-content: space-around;
}

.first-item .dropdown-submenu {
    top: 0 !important;
    bottom: auto !important;
}
</style>
