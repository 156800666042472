<template>
    <div>
        <fieldset class="fieldset my-2">
            <legend>LAYOUT</legend>
        </fieldset>
        <div class="row">
            <div class="col-6">
                <div class="d-flex flex-column">
                    <label
                        for="word-scramble-number-of-columns"
                        class="mb-1 f-11 text-muted d-flex w-100 justify-content-between"
                    >
                        <span class="font-weight-bold text-nowrap">Columns</span>
                        <span class="text-secondary">
                            {{ localData.number_of_columns }}
                        </span>
                    </label>
                    <b-form-input
                        id="word-scramble-number-of-columns"
                        v-model="localData.number_of_columns"
                        type="range"
                        min="1"
                        max="4"
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="d-flex flex-column">
                    <label for="order_numbers_by" class="font-weight-bold mb-1 f-11 text-muted">Order Numbers By</label>
                    <div class="d-flex">
                        <b-form-radio
                            v-model="localData.order_numbers_by"
                            name="order_numbers_by"
                            value="column"
                            style="width: 75px"
                        >
                            <div class="text-muted f-11" style="height: 1.5rem; line-height: 1.5rem">Column</div>
                        </b-form-radio>
                        <b-form-radio v-model="localData.order_numbers_by" name="order_numbers_by" value="row">
                            <div class="text-muted f-11" style="height: 1.5rem; line-height: 1.5rem">Row</div>
                        </b-form-radio>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="value.type === WORD_SCRAMBLE_TYPE_LINES" class="mt-4 row">
            <div class="col-6">
                <div class="d-flex flex-column">
                    <label for="word-scramble-line-length" class="mb-1 f-11 text-muted d-flex w-100 justify-content-between">
                        <span class="font-weight-bold text-nowrap">Line Length</span>
                        <span class="text-secondary">
                            {{ sizeInInches }}
                            <span class="ml-0.5">in</span>
                        </span>
                    </label>
                </div>

                <b-form-input
                    id="word-scramble-line-length"
                    v-model="localData.line_length"
                    type="range"
                    min="48"
                    :max="maxLineLength"
                />
            </div>
            <div class="col-6">
                <div class="d-flex flex-column">
                    <label for="word_scramble_line_position" class="font-weight-bold mb-1 f-11 text-muted">
                        Line Position
                    </label>
                    <div class="d-flex">
                        <b-form-radio
                            v-model="localData.line_position"
                            name="word_scramble_line_position"
                            value="after"
                            style="width: 75px"
                        >
                            <div class="text-muted f-11" style="height: 1.5rem; line-height: 1.5rem">After</div>
                        </b-form-radio>
                        <b-form-radio v-model="localData.line_position" name="word_scramble_line_position" value="below">
                            <div class="text-muted f-11" style="height: 1.5rem; line-height: 1.5rem">Underneath</div>
                        </b-form-radio>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { WordScrambleData } from '../../types/word-scramble'
import { WORD_SCRAMBLE_TYPE_LINES } from '../../store/helpers/documentHelpers'
import { EventBus } from '../../common/EventBus'

interface Data {
    localData: WordScrambleData
    WORD_SCRAMBLE_TYPE_LINES: number
    maxLineLength: number
}

export default defineComponent({
    name: 'WordScrambleLayout',
    props: {
        value: {
            type: Object as PropType<WordScrambleData>,
            required: true,
        },
    },
    data(): Data {
        return {
            localData: { ...this.value },
            WORD_SCRAMBLE_TYPE_LINES,
            maxLineLength: 356,
        }
    },
    computed: {
        sizeInInches(): string {
            const unformattedSize =
                typeof this.localData.line_length === 'string'
                    ? parseInt(this.localData.line_length)
                    : this.localData.line_length

            if (unformattedSize === 50) return '0.5'
            const size = unformattedSize / 96
            if (size < 0.5) return '0.5'
            return size.toFixed(1)
        },
        lineLength(): number {
            return typeof this.localData.line_length === 'string'
                ? parseInt(this.localData.line_length)
                : this.localData.line_length
        },
    },
    watch: {
        'localData.number_of_columns': {
            handler() {
                if (this.lineLength < 50) {
                    this.localData.line_length = 50
                }

                this.updateData()
            },
        },
        'localData.order_numbers_by': {
            handler() {
                this.updateData()
            },
        },
        'localData.line_position': {
            handler() {
                if (this.lineLength > this.maxLineLength) {
                    this.localData.line_length = this.maxLineLength
                }

                this.updateData()
            },
        },
        'localData.line_length': {
            handler(value: number) {
                if (value < 50) return
                if (value > this.maxLineLength) {
                    this.localData.line_length = this.maxLineLength
                }
                this.updateData()
            },
        },
    },
    created() {
        EventBus.$on('wordScrambleAnswerKeyWidth', (value: number) => this.setMaxLineLength(value))
    },
    methods: {
        updateData() {
            this.$emit('input', this.localData)
        },
        setMaxLineLength(value: number) {
            this.$nextTick(() => {
                if (this.lineLength > value) {
                    this.localData.line_length = value
                }

                this.maxLineLength = value
            })
        },
    },
})
</script>
