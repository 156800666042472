<template>
    <div class="d-flex align-items-center justify-content-between">
        <slot name="before-input"></slot>
        <b-input-group :class="groupClass">
            <b-input-group-prepend v-if="handle">
                <b-button :class="handleClass" :size="size" variant="secondary" tabindex="-1" :disabled="disabled">
                    <b-icon icon="grip-horizontal"></b-icon>
                </b-button>
            </b-input-group-prepend>

            <b-form-input
                v-if="editable"
                :id="refId"
                :ref="refId"
                :placeholder="placeholder"
                :size="size"
                :value="value"
                :class="inputClass"
                :disabled="disabled"
                @input="$emit('input', $event)"
                @keydown.enter="$emit('enter', $event)"
                @focus="$emit('focus')"
                @blur="$emit('blur')"
            ></b-form-input>

            <div
                v-else
                v-b-tooltip.hover.noninteractive
                title="Click to edit"
                class="disabled-word cursor-pointer rounded py-1 px-2 border"
                @click="$emit('goto')"
            >
                {{ value }}
            </div>

            <b-input-group-append v-if="inlineImage">
                <InlineImageBtn
                    :id="imageRefId"
                    :ref="imageRefId"
                    :image-id="imageId"
                    :input-index="index"
                    :column="column"
                    btn-class="rounded-right-small"
                    @deleted="$emit('deleteImage')"
                />
            </b-input-group-append>
        </b-input-group>
        <slot name="actions"></slot>
        <span
            v-b-tooltip.noninteractive.hover
            title="Remove"
            :disabled="disabled"
            :class="deleteButtonClass"
            class="ml-2 d-flex align-items-center justify-content-center delete-btn"
            @click="$emit('delete')"
        >
            <IconDelete :size="size" :disabled="disabled" class="text-danger" />
        </span>
    </div>
</template>

<script lang="ts">
import IconDelete from '../stories/components/icons/IconDelete.vue'
import InlineImageBtn from './AddInlineImageButton.vue'

export default {
    name: 'MultiOptionItem',
    components: {
        IconDelete,
        InlineImageBtn,
    },
    props: {
        index: {
            type: Number,
            default: undefined,
        },
        handle: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        groupClass: {
            type: [String, Object, Array],
            default: 'option-item-group',
        },
        handleClass: {
            type: [String, Object, Array],
            default: '',
        },
        inputClass: {
            type: [String, Object, Array],
            default: '',
        },
        deleteButtonClass: {
            type: [String, Object, Array],
            default: '',
        },
        size: {
            type: String,
            default: 'sm',
        },
        refId: {
            type: String,
            default: undefined,
        },
        imageRefId: {
            type: String,
            default: undefined,
        },
        inlineImage: {
            type: Boolean,
            default: false,
        },
        column: {
            type: String,
            default: 'term',
        },
        imageId: {
            type: String,
            default: undefined,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.option-item-group {
    width: 16.93125rem;
}

.sub-option-item-group {
    width: 12.957rem;
}

.checklist {
    .option-item-group {
        width: 18.93125rem;
    }
}

.sub-options-wrapper {
    .option-item-group {
        width: 15.83125rem;
    }
}

.delete-btn {
    cursor: pointer;
    width: 1.875rem;
    height: 1.875rem;

    &.hidden {
        opacity: 0;
        pointer-events: none;
        cursor: default;
        padding: 0.25rem 0.5rem;
    }

    &.visible {
        opacity: 1;
        padding: 0.25rem 0.5rem;
    }
}

.bingo-word-item-group {
    width: 82% !important;
}

.word-bank-item-group {
    width: 90% !important;
}

.no-show {
    visibility: hidden !important;
}
</style>

<style lang="scss">
@import 'Scss/base.scss';

.disabled-word {
    display: flex;
    flex: 1;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-color: rgb(206, 212, 218);
    background-color: rgb(233, 236, 239);
    border-width: 1px;
    padding: 0 12px;
    align-items: center;
}
</style>
