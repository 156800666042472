<template>
    <div class="card-body pb-1 pt-0">
        <div>
            <Draggable
                v-model="itemData.words"
                v-bind="{ handle: '.wordbank-word-handle', animation: 500 }"
                @start="dragOn = true"
                @end="dragOn = false"
                @change="updateData"
            >
                <div
                    v-for="(wordItem, wordIndex) in itemData.words"
                    :key="wordIndex"
                    class="mb-2"
                    @mouseenter="updateState('hovered', wordIndex, true)"
                    @mouseleave="updateState('hovered', wordIndex, false)"
                >
                    <MultiOptionItem
                        v-model="wordItem.word"
                        :ref-id="`word-bank-input-${wordIndex}`"
                        :index="wordIndex"
                        :delete-button-class="[deleteButtonClass(wordIndex), { 'no-show': !wordItem.manual || dragOn }]"
                        :handle="itemData.sort_order === 'manual'"
                        :editable="wordItem.manual"
                        :group-class="[{ 'w-100': wordItem.manual }, 'word-bank-item-group']"
                        placeholder="Word"
                        handle-class="wordbank-word-handle"
                        class="mb-2"
                        @input="updateWord(wordIndex, $event)"
                        @enter="handleEnterPress($event, wordIndex)"
                        @goto="goto('goto-id-' + wordItem.itemId, 'goto-container', wordItem)"
                        @delete="removeWord(wordIndex)"
                        @focus="updateState('active', wordIndex, true)"
                        @blur="updateState('active', wordIndex, false)"
                    />
                </div>
            </Draggable>

            <div v-if="!itemData.words.length" class="text-muted">
                <b-row class="justify-content-center my-2">
                    <b-col class="px-0" cols="1" align-self="center">
                        <!-- #3d8bff -->
                        <div class="swal2-icon swal2-info d-flex">
                            <p class="swal2-icon-content my-auto mx-auto">i</p>
                        </div>
                    </b-col>
                    <b-col cols="10" class="pr-0 pl-2 font-italic f-12 text-left">
                        <p class="mb-0 w-100" style="color: #3d8bff">
                            You can add to this word bank automatically by clicking
                            <b>"Add to word bank"</b>
                            on individual questions
                        </p>
                    </b-col>
                </b-row>
            </div>

            <div v-if="!itemData.words.length" class="text-muted f-11 font-italic">Or, add words manually:</div>
            <div class="d-flex mb-2">
                <b-input
                    ref="add-new-multi-option"
                    v-model="newWord"
                    type="text"
                    size="sm"
                    placeholder="Add Word"
                    class="word-bank-item-input"
                />
            </div>
        </div>

        <div class="d-flex">
            <div class="w-50 pr-1">
                <label class="font-weight-bold mb-1 f-11 text-muted mt-2">Sort Words:</label>
                <b-form-select
                    v-model="itemData.sort_order"
                    label="Body Font"
                    :options="options"
                    size="sm"
                    class="mt-0 mb-2"
                    @change="sort($event)"
                ></b-form-select>
            </div>

            <div class="w-50 pl-1">
                <label class="font-weight-bold mb-1 f-11 text-muted">Text Case</label>
                <b-form-select
                    v-model="itemData.text_case"
                    :options="textCaseOptions"
                    size="sm"
                    class="mt-0 mb-2"
                    @change="updateData"
                ></b-form-select>
            </div>
        </div>

        <BorderSettings v-model="itemData" class="mt-2" collapsible @input="updateData" />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import WordItem from '../../store/models/WordItem'
import { mapGetters } from 'vuex'
import BorderSettings from '../../widgets/BorderSettings.vue'
import { textCaseOptions } from '../../objects/TextCaseOptions'
import MultiOptionItem from '../MultiOptionItem.vue'
import WithItemEventState from '../../mixins/WithItemEventState'

export default defineComponent({
    name: 'WordBank',
    components: {
        MultiOptionItem,
        Draggable,
        BorderSettings,
    },
    mixins: [WithItemEventState],
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            options: [
                { value: 'random', text: 'Random' },
                { value: 'a-z', text: 'Alphabetically' },
                { value: 'manual', text: 'Manually' },
            ],
            textCaseOptions,
            customToolbar: [
                [
                    'bold',
                    'italic',
                    'underline',
                    {
                        color: ['#F00', '#0F0', '#00F', '#000', '#ededed'],
                    },
                ],
                [],
                [],
            ],
            itemData: {
                ...this.data,
            },
            sortedWords: [],
            newWord: '',
            dragOn: false,
        }
    },
    computed: {
        ...mapGetters({
            documentItems: 'document/documentItems',
        }),
    },
    watch: {
        newWord(val) {
            if (val.trim()) {
                this.addWord(val.trim())
            }
        },
    },
    mounted() {
        const firstInput = document.getElementById('word-bank-input-0')

        if (!firstInput) return

        firstInput.focus()
    },
    methods: {
        updateData() {
            this.$emit(
                'change',
                Object.assign(this.data, {
                    ...this.itemData,
                    words: this.itemData.words,
                }),
            )
        },
        updateWord(index, value) {
            if (!this.itemData.words[index]) return

            this.itemData.words[index].word = value
            this.updateData()
        },
        removeWord(index) {
            this.itemData.words.splice(index, 1)
            this.updateData()
        },
        addWord(val = '') {
            this.itemData.words.push(new WordItem(true, val))

            this.$nextTick(() => {
                let index = this.itemData.words.length - 1

                const wordBankInputElement = document.getElementById(`word-bank-input-${index}`)

                wordBankInputElement?.focus()

                this.newWord = ''
            })

            this.updateData()
        },
        sort(value) {
            if (value == 'random') {
                this.itemData.words.sort(() => 0.5 - Math.random())
            }

            if (value == 'a-z') {
                this.itemData.words.sort((wi1, wi2) => (!wi1.word ? 1 : !wi2.word ? -1 : wi1.word.localeCompare(wi2.word)))
            }

            this.updateData()
        },
        goto(refName, containerRef, wordItem) {
            if (wordItem.manual) {
                return
            }
            const item = this.documentItems.find((d) => d.id === wordItem.itemId)
            if (!item) {
                return
            }

            this.$emit('goto', { ref: refName, container: containerRef })

            this.$nextTick(() => {
                this.$store.dispatch('document/setWidgetStatus', {
                    openHeader: false,
                    focusedItem: item,
                })
            })
        },
        checkIndex(index) {
            const element = document.getElementById(`word-bank-input-${index + 1}`)
            if (typeof element !== 'undefined') {
                return Boolean(element)
            } else {
                return false
            }
        },
        handleEnterPress(e, index) {
            e.preventDefault()
            if (index === this.itemData.words.length - 1) {
                this.$refs['add-new-multi-option'].focus()
                return
            }
            this.checkIndex(index)
            let flag = false
            while (index <= this.itemData.words.length - 1 && !flag) {
                flag = this.checkIndex(index)
                if (!flag) {
                    index++
                }
            }
            if (index === this.itemData.words.length - 1) {
                this.$refs['add-new-multi-option'].focus()
            }

            const element = document.getElementById(`word-bank-input-${index + 1}`)

            if (Boolean(element)) {
                element?.focus()
            } else {
                this.$refs['add-new-multi-option'].focus()
            }
        },
    },
})
</script>

<style scoped>
.swal2-icon {
    width: 23px !important;
    height: 23px !important;
    margin: 0.2em auto 0.6em !important;
}

.swal2-icon-content {
    line-height: 0em !important;
    font-size: 1rem !important;
    font-weight: 600;
}

.swal2-icon .swal2-icon-content {
    height: 80%;
}

.swal2-icon.swal2-info {
    color: #3d8bff !important;
    border: 3px solid #3d8bff !important;
}

.word-bank-item-input {
    width: 89% !important;
}
</style>
