<template>
    <div class="d-flex justify-content-between mb-0 header-bar">
        <div class="d-flex">
            <div
                class="d-flex bg-secondary align-items-center justify-content-center handle-bar mr-2"
                :style="{ visibility: index > -1 ? 'visible' : 'hidden' }"
            >
                <IconEightDots />
            </div>
            <div class="d-flex align-items-center p-1">
                <HandwritingStyle
                    v-for="(style, key) in options"
                    :key="`style_${style.key}_${key}`"
                    :icon="style.icon || ''"
                    :name="style.name"
                    :title="style.tooltip"
                    :active="activeHandwritingLineStyle === style.name"
                    @click="$emit('change', style.name)"
                />
            </div>
        </div>
        <div v-if="active" class="d-flex justify-content-end align-items-center py-2">
            <div
                v-b-tooltip.hover.noninteractive
                title="Duplicate"
                class="header-btn text-lg d-flex align-items-center justify-content-center pointer mr-2"
                aria-label="Duplicate"
                role="button"
                @click.stop="$emit('duplicate', index)"
            >
                <IconDuplicate class="text-muted" />
            </div>
            <div
                v-b-tooltip.hover.noninteractive
                title="Remove Item"
                class="header-btn text-danger text-lg d-flex align-items-center justify-content-center mr-2"
                aria-label="Delete"
                role="button"
                @click.stop="$emit('delete', index)"
            >
                <IconDelete />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    handwritingStyles,
    HANDWRITING_TYPE_SOLID,
    HANDWRITING_TYPE_TRACE,
    HANDWRITING_TYPE_OUTLINE,
    HANDWRITING_TYPE_STROCK,
    HANDWRITING_TYPE_GUIDE,
} from '../store/helpers/documentHelpers'
import { HandwritingStyleObject } from '../objects/HandwritingStyle'
import HandwritingStyle from './HandwritingStyle.vue'
import IconEightDots from '../stories/components/icons/IconEightDots.vue'
import IconDelete from '../stories/components/icons/IconDelete.vue'
import IconDuplicate from '../stories/components/icons/IconDuplicate.vue'

interface Data {
    styles: HandwritingStyleObject[]
}

export default defineComponent({
    name: 'HandwritingStylePicker',
    components: {
        HandwritingStyle,
        IconEightDots,
        IconDelete,
        IconDuplicate,
    },
    props: {
        index: {
            type: Number,
            default: -1,
        },
        styleSelector: {
            type: String,
            default: 'Trace',
        },
        active: {
            type: Boolean,
            default: false,
        },
        font: {
            type: String,
            required: true,
        },
    },
    data(): Data {
        return {
            styles: handwritingStyles,
        }
    },
    computed: {
        options(): HandwritingStyleObject[] {
            return this.styles.filter((item) => {
                switch (item.key.toLowerCase()) {
                    case HANDWRITING_TYPE_SOLID.toLowerCase():
                        return item
                    case HANDWRITING_TYPE_TRACE.toLowerCase():
                        if (this.$fonts.isHwFont(this.font)) return item
                        return false
                    case HANDWRITING_TYPE_OUTLINE.toLowerCase():
                        if (!this.$fonts.isHwFont(this.font)) return item
                        return false
                    case HANDWRITING_TYPE_STROCK.toLowerCase():
                        if (this.$fonts.isHwFont(this.font)) return item
                        return false
                    case HANDWRITING_TYPE_GUIDE.toLowerCase():
                        if (this.$fonts.hasGuidedFont(this.font)) return item
                        return false
                    default:
                        return false
                }
            })
        },
        activeHandwritingLineStyle(): string {
            return this.$fonts.getActiveHandwritingLineStyle(this.font, this.styleSelector)
        },
    },
})
</script>

<style scoped lang="scss">
.header-bar {
    position: relative;
    color: #212529;
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 0;
    height: 2.7rem;

    .handle-bar {
        width: 3rem;
        padding: 0.1rem 0.5rem;
        border-radius: 0.25rem 0 0 0;
        cursor: move;
    }

    .header-btn {
        width: 1.5rem;
    }
}
</style>
