<template>
    <IconButton
        :class="deleteButtonClasses"
        icon="Delete"
        :size="size"
        tabindex="-1"
        variant="default-danger"
        @click="$emit('click')"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import IconButton from '../../stories/components/buttons/IconButton.vue'
import { borderRadiusOptions, iconSizeOptions } from '../../stories/mocks/StorybookOptions.mock'

interface Data {
    iconSizeOptions: string[]
}

export default defineComponent({
    name: 'DeleteButton',
    components: {
        IconButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        radius: {
            type: String,
            default: 'all',
            validator: (value: string) => borderRadiusOptions.includes(value),
        },
        size: {
            type: String,
            default: 'sm',
            validator: (value: string) => iconSizeOptions.includes(value),
        },
    },
    data(): Data {
        return {
            iconSizeOptions: iconSizeOptions,
        }
    },
    computed: {
        deleteButtonClasses(): object {
            return {
                radius: this.radius === 'all',
                'radius-left': this.radius === 'left',
                'radius-right': this.radius === 'right',
            }
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.radius {
    border-radius: $border-radius;
}

.radius-left {
    border-radius: $border-radius 0 0 $border-radius;
}

.radius-right {
    border-radius: 0 $border-radius $border-radius 0;
}
</style>
