<template>
    <div>
        <fieldset class="fieldset my-2">
            <legend>FORMAT</legend>
        </fieldset>
        <div class="d-flex flex-row mt-2 mx-3">
            <div class="range-alignment">
                <div class="d-flex w-100 mt-2 row">
                    <label class="mb-0 f-11 text-muted d-flex w-100 flex-row justify-content-between">
                        <span class="font-weight-bold text-nowrap">Font Size</span>
                        <span class="text-secondary">
                            {{ parseInt(itemStyle.font_size) }}
                            <span class="ml-0.5">pt</span>
                        </span>
                    </label>
                    <b-form-input v-model="font_size" class="mt-1" type="range" min="9" max="36" @input="updateData" />
                </div>
            </div>
            <div class="ml-2">
                <label class="font-weight-bold mb-1 f-11 text-muted">Alignment</label>
                <Alignment v-model="images_alignment" :options="alignmentOptions" size="sm" @input="updateData" />
            </div>
        </div>
        <div v-if="hasImage" class="d-flex flex-row mx-3">
            <div>
                <div class="d-flex w-100 mt-2 row">
                    <label class="mb-0 f-11 text-muted d-flex w-100 flex-row justify-content-between">
                        <span class="font-weight-bold text-nowrap">Image Height</span>
                        <span class="text-secondary">
                            {{ sizeInInches() }}
                            <span class="ml-0.5">in</span>
                        </span>
                    </label>
                    <b-form-input v-model="size" class="mt-1" type="range" min="48" max="200" step="1" @input="updateData" />
                </div>
            </div>
            <div class="ml-2">
                <label class="font-weight-bold mb-1 f-11 text-muted">Image Placement</label>
                <div class="d-flex w-100 flex-row">
                    <div class="d-flex align-items-center mr-3">
                        <b-form-radio v-model="text_placement" value="below" @change="updateData">
                            <div class="text-muted f-12" style="height: 1.5rem; line-height: 1.5rem">Above</div>
                        </b-form-radio>
                    </div>
                    <div class="d-flex align-items-center">
                        <b-form-radio v-model="text_placement" value="above" @change="updateData">
                            <div class="text-muted f-12" style="height: 1.5rem; line-height: 1.5rem">Below</div>
                        </b-form-radio>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Alignment from '../../widgets/alignment.vue'
import { MatchingDataSettings, MatchingStyle } from '../../types/matching'
import { AlignmentOption, ALIGNMENT_OPTIONS } from '../../types/alignment'

interface Data {
    font_size: number
    images_alignment: string
    size: number
    text_placement: string
    alignmentOptions: AlignmentOption[]
}

export default defineComponent({
    name: 'MatchingFormat',
    components: { Alignment },
    props: {
        item: {
            type: Object as PropType<MatchingDataSettings>,
            required: true,
        },
        itemStyle: {
            type: Object as PropType<MatchingStyle>,
            required: true,
        },
        hasImage: {
            type: Boolean,
            default: false,
        },
    },
    data(): Data {
        return {
            font_size: parseInt(this.itemStyle.font_size) || 14,
            images_alignment: this.item.images?.alignment || 'start',
            size: this.item.images?.size || 96,
            text_placement: this.item.images?.text_placement || 'below',
            alignmentOptions: ALIGNMENT_OPTIONS,
        }
    },
    watch: {
        itemStyle: {
            handler() {
                this.font_size = parseInt(this.itemStyle.font_size)
            },
            deep: true,
        },
        item: {
            handler() {
                this.images_alignment = this.item?.images?.alignment || 'start'
                this.size = this.item.images?.size || 96
                this.text_placement = this.item.images?.text_placement || 'below'
            },
            deep: true,
        },
    },
    methods: {
        updateData() {
            this.$emit('updateData', {
                font_size: this.font_size,
                images_alignment: this.images_alignment,
                size: this.size,
                text_placement: this.text_placement,
            })
        },
        sizeInInches(): string {
            if (this.size === 50) return '0.5'
            const size = this.size / 96
            if (size > 2) return '2.0'
            if (size < 0.5) return '0.5'
            return size.toFixed(1)
        },
    },
})
</script>

<style scoped>
.range-alignment {
    max-width: 175px;
}
</style>
