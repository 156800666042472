import {
    WORD_SCRAMBLE_TYPE_BLANK,
    WORD_SCRAMBLE_TYPE_LINES,
    WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
} from '../store/helpers/documentHelpers'

export const answerOptions = [
    { value: WORD_SCRAMBLE_TYPE_LINES, text: 'Line' },
    { value: WORD_SCRAMBLE_TYPE_BLANK, text: 'Blank' },
    { value: WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM, text: 'Multiple choice' },
]
