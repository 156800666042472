<template>
    <div class="d-flex flex-column">
        <label v-if="hasLabel" class="f-11 font-weight-bold text-muted text-nowrap" :class="labelClass">{{ label }}</label>
        <div class="row align-items-center ml-0">
            <button
                v-for="(option, index) in options"
                :key="`option-${option.value}`"
                :class="[
                    { 'mr-1': index < options.length - 1, active: selectedOption === option.value },
                    getBtnClass(option.value),
                ]"
                :disabled="disabled"
                :style="getStyle(option)"
                class="option-btn d-flex align-items-center justify-content-center"
                @click.stop="setOption(option.value)"
            >
                <IconLoader
                    v-if="Boolean(option.icon)"
                    :icon="option.icon || ''"
                    :size="size"
                    :class="{ 'mr-1': Boolean(option.label) }"
                />
                <span v-if="Boolean(option.label)">{{ option.label }}</span>
                <PremiumMarker v-if="option.isPremium" :feature="feature" class="ml-1 f-12 text-height-1" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, CSSProperties } from 'vue'
import { buttonSizeOptions, buttonVariantOptions } from '../../mocks/StorybookOptions.mock'
import type ButtonSelectorOption from '../../types/ButtonSelectorOption'
import IconLoader from '../icons/IconLoader.vue'

export default defineComponent({
    name: 'OptionButtonSelector',
    components: {
        IconLoader,
        PremiumMarker: () => import('../../../widgets/premium-marker.vue'),
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array as PropType<ButtonSelectorOption[]>,
            default: () => [],
        },
        active: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
            validator: (value: string) => buttonSizeOptions.includes(value),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'primary',
            validator: (value: string) => buttonVariantOptions.includes(value),
        },
        hasBorder: {
            type: Boolean,
            default: true,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        hasHover: {
            type: Boolean,
            default: true,
        },
        minWidth: {
            type: String,
            default: '',
        },
        labelClass: {
            type: String,
            default: '',
        },
        isPremium: {
            type: Boolean,
            default: false,
        },
        feature: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            selectedOption: this.active,
        }
    },
    computed: {
        hasLabel(): boolean {
            return Boolean(this.label.length)
        },
    },
    methods: {
        setOption(value: string) {
            this.selectedOption = value
            this.$emit('change', value)
        },
        getBtnClass(value: string): Object {
            return {
                btn: true,
                rounded: true,
                'border-0': !this.hasBorder,
                'bg-transparent': this.transparent,
                'no-hover': !this.hasHover || this.disabled,
                [`btn-${this.size}`]: !!this.size,
                [`btn-${this.variant}`]: this.selectedOption === value,
                [`btn-outline-${this.variant}`]: this.selectedOption !== value,
            }
        },
        getStyle(option: ButtonSelectorOption): CSSProperties {
            return {
                minWidth: Boolean(this.minWidth) ? this.minWidth : Boolean(option.label) ? '4.5rem' : '1.375rem',
                lineHeight: !Boolean(option.label) && Boolean(option.icon) ? 1 : 1.5,
                padding: '0.25rem',
                paddingBottom: Boolean(option.label) ? '0.25rem' : '0.3125rem',
            }
        },
    },
})
</script>

<style scoped lang="scss">
.btn-primary.active {
    background-color: var(--primary-50) !important;
    color: var(--primary) !important;
}
</style>
