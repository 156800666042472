<template>
    <div class="puzzle-form-section position-relative" style="user-select: none">
        <div v-if="!widgetEnabled" class="widget-overlay" @click="(e) => e.preventDefault()"></div>
        <div
            v-if="addItemDropDownOpen"
            class="widget-overlay add-item-overlay"
            :style="'opacity: 0.4'"
            @click="(e) => e.preventDefault()"
        ></div>
        <div class="fixed-body-style d-flex align-items-center justify-content-between" style="z-index: 102">
            <div class="d-flex justify-content-end">
                <StartOverButton v-if="documentItems.length || images.length" @startOver="clearWidgets" />
            </div>

            <div class="d-flex align-items-center image-uploader">
                <ImageUploader class="ml-2" @visible-changed="(e) => (widgetEnabled = !e)" />

                <BodyStyleSettings
                    v-if="isWorksheet"
                    class="ml-2"
                    @change="onChange('index', $event)"
                    @visible-changed="(e) => (widgetEnabled = !e)"
                />
            </div>
        </div>

        <div class="d-flex flex-grow-1 overflow-hidden position-relative mt-3">
            <div
                id="goto-container"
                ref="container"
                class="puzzle-form-container position-relative pr-2 pb-2 mb-2"
                :class="goToContainerClasses"
                @scroll="handleScroll"
            >
                <ItemFrame
                    v-for="(item, index) in sideBarItemsSorted"
                    :key="item.title"
                    :title="item.title"
                    :opened="item.opened"
                    :goto-id="item.gotoId"
                    :add-disabled="item.addDisabled"
                    :editable="item.editable"
                    :hide-options="item.hideOptions"
                    :draggable="item.draggable"
                    :is-first-item="index === 0"
                    @goto="goto($event, null)"
                    @on-edit="
                        $nextTick(() => {
                            $store.dispatch('document/setWidgetStatus', item.setWidgetStatus)
                        })
                    "
                >
                    <component
                        :is="item.component.name"
                        :data="item.component.data"
                        :index="index"
                        @change="item.component.changeHandler"
                    />
                </ItemFrame>

                <!-- Activity Forms -->
                <Draggable
                    v-if="isWorksheet"
                    v-model="documentItems"
                    :animation="500"
                    :empty-insert-threshold="100"
                    :scroll-sensitivity="50"
                    :scroll-speed="5"
                    :force-fallback="true"
                    ghost-class="ghost-card"
                    drag-class="draging-card"
                    handle=".handle-widget"
                    filter=".prevent-drag"
                    @start="dragStarted"
                    @end="dragEnded"
                    @change="$store.dispatch('document/renumberItems')"
                >
                    <ItemFrame
                        v-for="(item, index) in documentItems"
                        :key="'item-' + item.id"
                        :title="getItemTitle(item.type)"
                        :index="index"
                        :goto-id="'preview-' + item.id"
                        :editable="true"
                        :removeable="true"
                        :opened="currentWidget.focusedItem && currentWidget.focusedItem.id === item.id && !closeWidget"
                        :draggable="true"
                        :item="item"
                        :duplicateable="true"
                        :is-scrolling="isScrolling"
                        :dragging="dragOn !== -1"
                        @goto="goto($event, item)"
                        @popover-closed="isScrolling = false"
                        @on-edit="
                            $nextTick(() => {
                                $store.dispatch('document/setWidgetStatus', {
                                    openHeader: false,
                                    focusedItem: currentWidget.focusedItem === item ? undefined : item,
                                })
                            })
                        "
                        @on-duplicate="duplicateItem(index)"
                        @on-copy="copyItem(index)"
                        @on-remove="removeItem($event, index)"
                        @addItemDropdownOpened="addItemDropdownOpened"
                        @itemHide="item.hide = !item.hide"
                    >
                        <component
                            :is="inputType(item.type)"
                            v-if="item.type"
                            :key="item.id"
                            :index="index"
                            :data="item.data"
                            :clicked-index="currentWidget.focusedItem?.clickedIndex"
                            :item-style.sync="item.style"
                            @change="onChange(index, $event)"
                            @goto="goto"
                        />
                    </ItemFrame>
                </Draggable>
            </div>
        </div>

        <div class="bottom-action-panel p-2 text-center">
            <button
                data-test-id="publish-button"
                class="btn btn-success rounded-pill px-4"
                style="transform: translateX(-8px)"
                @click="showPrintableModal"
            >
                {{ makePrintableText }}
            </button>

            <AddItemModal />
            <PrintableModal />
            <OnboardingModal />
            <OnboardingComplete />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import { mapGetters, mapState } from 'vuex'
import ImageUploader from './ImageUploader.vue'
import BodyStyleSettings from './BodyStyleSettings.vue'
import WorksheetHeader from './WorksheetHeader.vue'
import Instruction from './InstructionBox.vue'
import BlankSpace from './BlankSpace.vue'
import BasicText from './BasicText.vue'
import StandardInstruction from './StandardInstruction.vue'
import WordsDefinition from './WordsDefinition.vue'
import PageSetup from './PageSetup.vue'
import OpenResponse from './OpenResponse.vue'
import MultipleChoice from './MultipleChoice.vue'
import Checklist from './CheckList.vue'
import Handwriting from './Handwriting.vue'
import WordScramble from './WordScramble.vue'
import Matching from './Matching.vue'
import Divider from './Divider.vue'
import PageBreak from './PageBreak.vue'
import WordBank from './WordBank.vue'
import SectionHeader from './SectionHeader.vue'
import FillInTheBlank from './FillInTheBlank.vue'
import BingoCardSetup from './BingoCardSetup.vue'
import BingoWords from './BingoWords.vue'
import BingoCallList from './BingoCallList.vue'

import ItemFrame from '../../widgets/item-manager/item-frame.vue'
import type { ItemFrameItem } from '../../objects/ItemFrameItem'
import AddQuestion from '../../widgets/item-manager/add-question.vue'
import charmap from '../../widgets/charmap.vue'

import { bingo_types, element_types, flashcard_types, worksheet_activities_types } from '../../objects/Document'
import Errors from '../../libraries/errors'
import { EventBus } from '../../common/EventBus'

import PrintableModal from '../PrintableModal.vue'
import OnboardingModal from '../OnboardingModal.vue'
import OnboardingComplete from '../OnboardingComplete.vue'

import Activity from '../../store/models/Activity'
import Common from '../../mixins/Common'
import StatsigHelper from '../../mixins/StatsigHelper'
import UserEvent from '../../objects/UserEvent'
import wmAlert from '../../helpers/wmAlerts'
import StartOverButton from '../StartOverButton.vue'
import WithAutoScroll from '../../mixins/WithAutoScroll'
import { printActionText } from '../../config/Config'
import WithAddItem from '../../mixins/WithAddItem'

import AddItemModal from '../../widgets/item-manager/AddItemModal.vue'

export default defineComponent({
    name: 'SideBar',
    components: {
        StartOverButton,
        BingoWords,
        BingoCardSetup,
        BingoCallList,
        PrintableModal,
        OnboardingModal,
        OnboardingComplete,
        Draggable,
        ImageUploader,
        BodyStyleSettings,
        WorksheetHeader,
        BasicText,
        Instruction,
        OpenResponse,
        MultipleChoice,
        Checklist,
        Handwriting,
        WordScramble,
        Matching,
        Divider,
        BlankSpace,
        PageBreak,
        WordBank,
        SectionHeader,
        ItemFrame,
        AddQuestion,
        FillInTheBlank,
        StandardInstruction,
        WordsDefinition,
        PageSetup,
        charmap,
        AddItemModal,
    },
    mixins: [Common, StatsigHelper, WithAutoScroll, WithAddItem],
    data() {
        return {
            showActivities: false,
            openHeader: true,
            focusedItem: undefined,
            errors: new Errors(),
            dragOn: -1,
            widgetEnabled: true,
            closeWidget: false,
            addItemDropDownOpen: false,
            isScrolling: false,
        }
    },
    computed: {
        ...mapState({
            document: 'document',
            user: 'user',
            subscription: 'subscription',
            cards: 'cards',
        }),
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isAdmin: 'user/isAdmin',
            style: 'document/documentStyle',
            documentType: 'document/documentType',
            documentTypes: 'document/allDocumentTypes',
            isDcoumentPublishable: 'document/isPublishable',
            documentModel: 'document/documentModel',
            items: 'document/documentItems',
            currentWidget: 'document/currentWidget',
            persistAction: 'document/persistAction',
            isWorksheet: 'document/isWorksheet',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            flashcard: 'document/flashcard',
            onboarding: 'document/onboarding',
            neverHadTrial: 'user/neverHadTrial',
            onboardingComplete: 'user/onboardingComplete',
            entityType: 'document/entityType',
            loadingAbAction: 'abtests/loadingAbAction',
            worksheetTitle: 'abtests/worksheetTitle',
            bingoTitle: 'abtests/bingoTitle',
            flashcardTitle: 'abtests/flashcardTitle',
            worksheetImages: 'document/worksheetImages',
            flashcardImages: 'document/flashcardImages',
            bingoImages: 'document/bingoImages',
            getWordBank: 'document/getSortedWordBank',
            worksheetHeaderAndTitleLabelCombination: 'abtests/worksheetHeaderAndTitleLabelCombination',
            storedDocument: 'document/storedDocument',
        }),
        images() {
            if (this.entityType === 'worksheet') return this.worksheetImages
            if (this.entityType === 'flashcard') return this.flashcardImages
            if (this.entityType === 'bingo') return this.bingoImages
            return []
        },
        showOnboardingModal() {
            if (
                !this.isLoggedIn ||
                this.isAdmin ||
                this.user.user.has_plan ||
                this.user.user.subscription_plan != 'Free' ||
                this.onboardingComplete
            ) {
                return false
            }

            return !this.user.user.trialed && !this.cards.cards.length
        },
        instructionsData() {
            return this.document[this.documentType].instruction
        },
        pageSetupData() {
            return {
                flashcard_item_height: this.flashcard.flashcard_item_height,
                page_setup: this.flashcard.page_setup,
            }
        },
        makePrintableText() {
            if (this.entityType) return printActionText[this.entityType]
            return 'Make Printable'
        },
        documentItems: {
            get() {
                return this.items
            },
            async set(values) {
                await this.$store.dispatch('document/dragSortItems', values)
            },
        },
        signUpTitle() {
            let text = this.worksheetTitle
            if (this.entityType === 'worksheet') text = this.worksheetTitle
            if (this.entityType === 'flashcard') text = this.flashcardTitle
            if (this.entityType === 'bingo') text = this.bingoTitle
            return text
        },
        bingoSideBarItems(): ItemFrameItem[] {
            return [
                {
                    title: 'Header',
                    component: {
                        name: 'WorksheetHeader',
                        data: undefined,
                        changeHandler: async (event: any) => await this.onChange('index', event),
                    },
                    index: 1,
                    opened: this.currentWidget.openHeader,
                    gotoId: 'docHeader',
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    addDisabled: !this.isWorksheet,
                    setWidgetStatus: {
                        openHeader: !this.currentWidget.openHeader,
                        focusedItem: undefined,
                    },
                },
                {
                    title: 'Instructions',
                    component: {
                        name: 'StandardInstruction',
                        data: this.instructionsData,
                        changeHandler: async (updated: any) => await this.setInstructionsData(updated),
                    },
                    index: 2,
                    opened: this.currentWidget.openInstructions,
                    gotoId: 'instructions',
                    addDisabled: true,
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    setWidgetStatus: {
                        openInstructions: !this.currentWidget.openInstructions,
                        focusedItem: undefined,
                    },
                },
                {
                    title: 'Card Setup',
                    component: {
                        name: 'BingoCardSetup',
                        data: {},
                        changeHandler: async (event: any) => await this.onChange('index', event),
                    },
                    index: 0,
                    opened: this.currentWidget.bingoCardSetup,
                    gotoId: 'bingo-card-setup',
                    addDisabled: true,
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    isFirstItem: true,
                    setWidgetStatus: {
                        bingoCardSetup: !this.currentWidget.bingoCardSetup,
                        focusedItem: undefined,
                    },
                },
                {
                    title: 'Bingo Words',
                    component: {
                        name: 'BingoWords',
                        data: {},
                        changeHandler: async (event: any) => await this.onChange('index', event),
                    },
                    index: 3,
                    opened: this.currentWidget.openBingoWords,
                    gotoId: 'bingo_words',
                    editable: true,
                    addDisabled: true,
                    hideOptions: true,
                    draggable: false,
                    setWidgetStatus: {
                        openBingoWords: !this.currentWidget.openBingoWords,
                        wordsDefinition: !this.currentWidget.wordsDefinition,
                        focusedItem: undefined,
                    },
                },
                {
                    title: 'Call List',
                    component: {
                        name: 'BingoCallList',
                        data: {},
                        changeHandler: async (event: any) => await this.onChange('index', event),
                    },
                    index: 4,
                    opened: this.currentWidget.bingoCallList,
                    gotoId: 'bingo_call_list',
                    addDisabled: true,
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    setWidgetStatus: {
                        bingoCallList: !this.currentWidget.bingoCallList,
                        pageSettings: !this.currentWidget.pageSettings,
                        focusedItem: undefined,
                    },
                },
            ]
        },
        flashcardSideBarItems(): ItemFrameItem[] {
            return [
                {
                    title: 'Header',
                    component: {
                        name: 'WorksheetHeader',
                        data: undefined,
                        changeHandler: async (event: any) => await this.onChange('index', event),
                    },
                    index: 0,
                    opened: this.currentWidget.openHeader,
                    gotoId: 'docHeader',
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    addDisabled: !this.isWorksheet,
                    setWidgetStatus: {
                        openHeader: !this.currentWidget.openHeader,
                        focusedItem: undefined,
                    },
                },
                {
                    title: 'Instructions',
                    component: {
                        name: 'StandardInstruction',
                        data: this.instructionsData,
                        changeHandler: async (updated: any) => await this.setInstructionsData(updated),
                    },
                    index: 1,
                    opened: this.currentWidget.openInstructions,
                    gotoId: 'instructions',
                    addDisabled: true,
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    setWidgetStatus: {
                        openInstructions: !this.currentWidget.openInstructions,
                        focusedItem: undefined,
                    },
                },
                {
                    title: 'Words & Definitions',
                    component: {
                        name: 'WordsDefinition',
                        data: undefined,
                        changeHandler: () => {},
                    },
                    index: 2,
                    opened: this.currentWidget.wordsDefinition,
                    gotoId: 'words_definitions',
                    editable: true,
                    addDisabled: true,
                    hideOptions: true,
                    draggable: false,
                    setWidgetStatus: {
                        wordsDefinition: !this.currentWidget.wordsDefinition,
                        focusedItem: undefined,
                    },
                },
                {
                    title: 'Page Setup',
                    component: {
                        name: 'PageSetup',
                        data: this.pageSetupData,
                        changeHandler: async (updated: any) => await this.setPageSetupData(updated),
                    },
                    index: 3,
                    opened: this.currentWidget.pageSettings,
                    gotoId: 'page_setup',
                    addDisabled: true,
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    setWidgetStatus: {
                        pageSettings: !this.currentWidget.pageSettings,
                        focusedItem: undefined,
                    },
                },
            ]
        },
        worksheetSideBarItems(): ItemFrameItem[] {
            return [
                {
                    title: this.worksheetHeaderAndTitleLabelCombination.header,
                    component: {
                        name: 'WorksheetHeader',
                        data: undefined,
                        changeHandler: () => {},
                    },
                    index: 0,
                    opened: this.currentWidget.openHeader,
                    gotoId: 'docHeader',
                    editable: true,
                    hideOptions: true,
                    draggable: false,
                    addDisabled: !this.isWorksheet,
                    setWidgetStatus: {
                        openHeader: !this.currentWidget.openHeader,
                        focusedItem: undefined,
                    },
                    addItemDropdownOpened: this.addItemDropdownOpened,
                },
            ]
        },
        sideBarItemsSorted(): ItemFrameItem[] {
            if (this.isBingo) return this.bingoSideBarItems

            let items = this.worksheetSideBarItems
            if (this.isFlashcard) items = this.flashcardSideBarItems

            return [...items].sort((a, b) => a.index - b.index)
        },
        goToContainerClasses() {
            const classes = []
            if (this.addItemDropDownOpen) {
                classes.push('overflow-hidden add-menu-open')
            }
            if (this.entityType === 'bingo') {
                classes.push('top-padding-wm-f')
            } else {
                classes.push('top-padding-wm-f')
            }
            return classes.join(' ')
        },
    },
    watch: {
        documentItems: {
            handler(oldItems, newItems) {
                if (oldItems.length < newItems.length) {
                    setTimeout(() => {
                        let elements = document.getElementsByClassName('item-frame')
                        let lastElement = elements[elements.length - 1]
                        if (lastElement) {
                            this.scrollToPosition(lastElement, 'start')
                        }
                    }, 100)
                }
            },
        },
        showActivities() {
            setTimeout(() => {
                let addQuestionElm = document.getElementById('add-question')
                if (addQuestionElm) {
                    this.scrollToPosition(addQuestionElm, 'start')
                }
            }, 100)
        },
    },
    created() {
        window.addEventListener('keydown', this.addItemHandler)
    },
    async mounted() {
        if (this.isWorksheet && Boolean(this.items.length) && (Boolean(this.storedDocument) || Boolean(this.document.id))) {
            await this.$store.dispatch('document/setWidgetStatus', { openHeader: false })
            this.$nextTick(async () => {
                await this.showLastAddItemWidget()
            })
        }
    },
    methods: {
        async handleScroll() {
            await this.$store.dispatch('document/setIsScrollingSideBar', true)
        },
        async setInstructionsData(updated) {
            await this.onChange('index', {})

            if (this.isFlashcard) {
                await this.$store.dispatch('document/setFlashcard', {
                    instruction: updated,
                })
            } else if (this.isBingo) {
                await this.$store.dispatch('document/setBingo', {
                    instruction: updated,
                })
            }

            this.$nextTick(async () => {
                await this.$store.dispatch('document/scaleDocument', true)
            })
        },
        async setPageSetupData(updated) {
            await this.$store.dispatch('document/setFlashcard', {
                ...updated,
            })

            this.$nextTick(async () => {
                await this.$store.dispatch('document/scaleDocument', true)
            })
        },
        async showPrintableModal() {
            if (!this.isLoggedIn) {
                await this.$store.dispatch('abtests/initialize', {
                    completed: async () => {
                        await this.$store.dispatch('abtests/loadDomainCreateAccountHeaderTests')
                    },
                })
            }

            //log to Statsig a publish-button-click event
            await this.logClickMakePrintable(this.documentType)

            if (this.showOnboardingModal) {
                this.user.user.request_no_email != null && this.user.user.occupation != null
                    ? await this.showPrintingPaywall(true)
                    : await this.initiateOnboarding()
            } else if (this.isLoggedIn && !this.isAdmin && this.showPremiumWall) {
                await this.showPrintingPaywall(true)
            } else {
                await this.$store.dispatch('document/setOnPublishing', true)
                if (!this.isLoggedIn) {
                    this.$modals.open('logIn')
                    this.$modals.logIn.signUpTitle = this.signUpTitle
                    this.$modals.logIn.print_panel = true
                    this.$modals.logIn.active_tab = 'sign_up'
                } else {
                    return this.$bvModal.show('printable-modal')
                }
            }
        },
        async initiateOnboarding() {
            await this.$store.dispatch('document/setOnPublishing', true)
            await this.$store.dispatch('document/setOnboarding', true)
            this.onboardingComplete ? this.$bvModal.show('checkout-modal') : this.$bvModal.show('onboarding-modal')
        },
        dragStarted({ oldIndex }) {
            this.dragOn = oldIndex
        },
        dragEnded() {
            this.dragOn = -1
            this.closeWidget = false
        },
        async intentToSave() {
            if (this.isLoggedIn) {
                if (!this.document.id) {
                    this.$modals.open('filename')
                } else {
                    try {
                        await this.$store.dispatch('document/updateDocumentRequest', this.documentModel)
                    } catch (error) {
                        await this.$store.dispatch('document/setSaveDocument', false)
                        await this.$store.dispatch('document/setSaveError', true)
                    }
                }
            } else {
                await this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
                await this.$logTrackingEvent(UserEvent.SIGN_UP_SAVE_WORKSHEET)
            }
        },
        showActivitySelectForm() {
            this.showActivities = true
        },
        async addNewItem(type) {
            await this.$store.dispatch('document/pushNewItemWithType', type)
        },
        inputType(type) {
            return type.replace(/_/g, '-')
        },
        getItemTitle(type) {
            return [...bingo_types, ...flashcard_types, ...worksheet_activities_types, ...element_types].find(
                (t) => t.key === type,
            )?.name
        },
        async onChange(index: number, data) {
            if (this.isWorksheet && !data.hasOwnProperty('non_item')) {
                this.documentItems[index].update(data)
            }

            if (this.isWorksheet) {
                await this.setNumbering()
            }

            await this.$store.dispatch('document/storeDocumentState')
            if (this.isLoggedIn) {
                await this.$store.dispatch(this.persistAction)
            }

            if (this.isBingo) {
                this.$nextTick(async () => {
                    await this.$store.dispatch('document/paginateCallList', this.getWordBank)
                })
            }

            this.$nextTick(async () => {
                await this.$store.dispatch('document/scaleDocument', false)
            })
        },
        // TODO: Check if this can be removed
        moveItemUp(index: number) {
            let items = copyObject(this.documentItems)
            let newIndex
            if (index) {
                newIndex = index - 1
            } else {
                newIndex = items.length - 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in it's new index
            items.splice(newIndex, 0, item)

            //write over the original worksheet items with our new one.
            this.documentItems = items
            this.$nextTick(() => {
                document.getElementById('handle-' + newIndex).focus()
            })
        },
        // TODO: Check if this can be removed
        moveItemDown(index) {
            let items = copyObject(this.documentItems)

            let newIndex
            if (index == items.length - 1) {
                newIndex = 0
            } else {
                newIndex = index + 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in it's new index
            items.splice(newIndex, 0, item)

            //write over the original worksheet items with our new one.
            this.documentItems = items

            this.$nextTick(() => {
                document.getElementById('handle-' + newIndex)?.focus()
            })
        },
        getFocus(index: number) {
            return document.getElementById('worksheet-input-' + index)
        },
        setFocus(select) {
            let elem = this.getFocus(this.items.length - 1)

            if (elem) {
                elem.focus()
                this.scrollToPosition(elem, 'start')
                //select the text of the latest item on handwriting worksheets
                if (['handwriting', 'open_response'].includes(this.worksheet.type) && select) {
                    elem.select()
                }
            }
        },
        resetFocus() {
            if (this.$nav.active_panel == 'body') {
                let elem = this.getFocus(0)
                if (elem) {
                    elem.focus()
                    elem.blur()
                    document.getElementById('body-panel-content').scrollTop = 0
                }
            }
        },
        showDefinition() {
            return (
                !['word_search', 'handwriting', 'multiple_choice'].includes(this.worksheet.type) &&
                this.document.entity_type !== 'bingo'
            )
        },
        showTerm() {
            return !['open_response'].includes(this.worksheet.type)
        },
        async sortItemList(field, direction) {
            await this.$store.dispatch('document/sortItems', {
                field: field,
                direction: direction,
            })
        },
        // TODO: Check why itemSort is not initialized
        resetItemSort() {
            this.itemSort = { field: false, dir: false }
        },
        // TODO: Check why itemSort is not initialized
        async sortItems() {
            if (this.itemSort.field) {
                if (this.itemSort.field == 'randomize') {
                    this.$store.dispatch('document/randomizeItems')
                } else {
                    await this.sortItemList(this.itemSort.field, this.itemSort.dir)
                }

                this.justSorted = true
            }
        },
        // TODO: Check if this can be removed
        async tab(type, e, index) {
            if (index == this.items.length - 1) {
                if (
                    type == 'handwriting' ||
                    (type == 'definition' && this.showDefinition() && this.worksheet.type != 'fill_in_the_blank') ||
                    (type == 'term' &&
                        this.showDefinition() == false &&
                        !['handwriting', 'multiple_choice'].includes(this.worksheet.type)) ||
                    (type == 'term' && this.worksheet.type == 'fill_in_the_blank')
                ) {
                    e.preventDefault()
                    this.tabIndex = index + 1
                    await this.addNewItem()
                }
            }
        },
        async duplicateItem(index: number) {
            const itemType = this.documentItems[index].type

            if (itemType === 'word_bank') {
                return
            }

            await this.$store.dispatch('document/duplicateItem', index).then(() => {
                this.currentWidget.focusedItem = this.documentItems[index + 1]
                this.currentWidget.openHeader = false
            })

            await this.$logTrackingEvent(UserEvent.ADD_ACTIVITY, itemType)

            this.$nextTick(async () => {
                await this.$store.dispatch('document/renumberItems')
                await this.$store.dispatch('document/scaleDocument', true)
            })

            this.$forceNextTick(() => {
                this.scrollIntoView(`preview-${this.documentItems[index + 1].id}`)
                this.scrollIntoView(`goto-id-${this.documentItems[index + 1].id}`)
            })
        },
        async copyItem(index) {
            await this.$store.dispatch('document/copyItem', index).then(() => {
                this.currentWidget.focusedItem = this.documentItems[index + 1]
                this.currentWidget.openHeader = false
            })
        },
        // TODO: Refactor
        async removeItem(e: any, index: number): Promise<void> {
            const focusedItem = this.documentItems[index]
            const original = new Activity(focusedItem.type)
            let shouldDelete: boolean = true
            let result: SweetAlertResult<any>

            if (JSON.stringify(focusedItem.data) != JSON.stringify(original.data)) {
                if (focusedItem.type !== 'word_bank') {
                    result = await wmAlert.confirm({
                        title: 'Are you sure you want to delete?',
                        icon: 'question',
                    })
                    shouldDelete = result.isConfirmed
                } else if (focusedItem.data.words.length) {
                    result = await wmAlert.confirm({
                        title: 'Are you sure you want to delete?',
                        icon: 'question',
                    })
                    shouldDelete = result.isConfirmed
                }

                if (!shouldDelete) return
            }

            this.$nextTick(async () => {
                await this.$store.dispatch('document/removeItem', index)
                await this.$logTrackingEvent(UserEvent.REMOVE_ACTIVITY, focusedItem.type)
                if (focusedItem.type === 'word_bank') this.handleWordBankFib(focusedItem.data.id)
                this.rescaleDoc()

                if (Boolean(e.ref)) {
                    this.$nextTick(async () => {
                        const { widget } = this.getWidgetItems(e.ref)
                        if (!widget) await this.showLastAddItemWidget()
                    })
                }
            })
        },
        rescaleDoc() {
            this.$nextTick(async () => {
                await this.$store.dispatch('document/scaleDocument', true)
            })
        },
        handleWordBankFib(id) {
            this.documentItems.forEach((item) => {
                if (item.data.wordbank_id === id) {
                    item.data.include_wordbank = 0
                }
            })
        },
        async clearItems() {
            await this.$store.dispatch('document/clearItems')
            this.$nextTick(async () => {
                await this.$store.dispatch('document/scaleDocument', true)
            })
        },
        async swapItem(index) {
            await this.$store.dispatch('document/swapItem', index)
        },
        // TODO: Check if this can be removed
        async swapAllItems() {
            for (let i = 0; i < this.documentItems.length; i++) {
                await this.swapItem(i)
            }
        },
        goto(e, item) {
            if (item?.type === 'page_break') {
                return
            }
            this.$emit('goto', e)
        },
        clearWidgets() {
            const content = this.isWorksheet
                ? 'This will revert your document to a blank template and cannot be undone.'
                : 'This will permanently delete all words and formatting. It cannot be undone.'

            wmAlert
                .confirm({
                    confirmButtonText: 'Clear All',
                    html: content,
                })
                .then(async (result) => {
                    if (!result.isConfirmed) return

                    // clear document
                    this.$nextTick(async () => {
                        if (this.isWorksheet) {
                            this.documentItems = []
                            await this.$logTrackingEvent(UserEvent.CLEAR_ALL_ACTIVITIES)
                        } else if (!this.isBingo) {
                            this.documentItems = [
                                { definition: '', term: '', index: 0, term_image: '', definition_image: '' },
                            ]
                        }

                        await this.$store.dispatch('document/resetDocumentHeader')
                        await this.$store.dispatch('document/resetDocumentStyle')

                        if (this.isBingo) await this.$store.dispatch('document/clearItems')

                        // Reset the document state
                        await this.$store.dispatch('document/resetDocument', this.document)
                        this.rescaleDoc()

                        // Set focus to Worksheet header
                        await this.$store.dispatch('document/setWidgetStatus', {
                            openHeader: true,
                            focusedItem: undefined,
                        })
                    })

                    await this.$store.dispatch('document/setDocument', window.doc)

                    // Scroll document back to top
                    this.$emit('goto', {
                        ref: 'docHeader',
                        container: 'workspaceContainer',
                    })
                })
        },
        addItemHandler(e) {
            if (!e.repeat && ((e.metaKey && e.key === 'Enter') || (e.ctrlKey && e.key === 'Enter'))) {
                EventBus.$emit('keyboard-event-add-item', e)
            }
        },
        addItemDropdownOpened(e) {
            this.addItemDropDownOpen = e
        },
        async setNumbering() {
            await this.$store.dispatch('document/renumberItems')
        },
    },
})
</script>

<style lang="scss">
.fixed-body-style {
    top: 0;
    left: 15px;
    z-index: 100;
    position: sticky;
    margin-right: 1.365rem;
}

.swal2-icon {
    width: 40px;
    height: 40px;
}

.swal2-icon .swal2-icon-content {
    font-size: 25px;
}

.swal2-title {
    font-size: 20px;
}

.draging-card {
    opacity: 0;
}

.doc-control {
    background-color: #3c3d3d;
}

.draging-card {
    display: none;
}

.sortable-chosen.ghost-card {
    .widget-drawer,
    .frame-body,
    .frame-footer {
        display: none !important;
    }
}

.image-uploader {
    overscroll-behavior: contain;
}

@media only screen and (max-width: 768px) {
    .puzzle-form-section [class*='handle-']:before {
        inset: 0;
    }
}

.add-menu-open {
    padding-right: 20px !important;
}

.top-padding-wm-f {
    padding-top: 5px !important;
}
</style>
