<template>
    <div class="card-body p-2">
        <div class="card-body border rounded p-2" @drop="(e) => e.preventDefault()">
            <b-form-checkbox
                id="instruction_visible"
                v-model="instruction_visible"
                class="subtitle font-weight-bold text-muted"
                name="instruction_visible"
                :value="1"
                :unchecked-value="0"
            >
                Instructions:
            </b-form-checkbox>

            <div class="instruction-input position-relative">
                <VueEditor
                    :ref="`widget_${index}`"
                    v-model="instructionInput"
                    :editor-toolbar="customToolbar"
                    :editor-options="editorSettings"
                    placeholder="Write your instructions here."
                    @text-change="updateData"
                ></VueEditor>
            </div>
        </div>

        <AdvancedOptionsToggleButton :active="showAdvancedOptions" @click="showAdvancedOptions = !showAdvancedOptions" />
        <div v-if="showAdvancedOptions">
            <div class="row w-100 m-0 my-2 align-items-baseline">
                <div class="col-md-6 p-0 d-flex flex-column mt-1">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="instruction-font"
                            key="instruction-font"
                            v-model="itemStyle.font"
                            class="mt-0 mb-0 pb-0 w-100"
                            :options="false"
                            size="sm"
                            widget="instruction"
                            @changed="updateFont"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <ColorPicker v-model="itemStyle.color" label="Font Color" />
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column mt-1 mb-0">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <div class="font-weight-bold text-nowrap">Font Size</div>
                        <div class="text-secondary">
                            {{ parseInt(itemStyle.font_size) }}
                        </div>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            v-model="itemStyle.font_size"
                            class="flex-glow-1"
                            type="range"
                            min="6"
                            max="48"
                        ></b-form-input>
                    </div>
                </div>
            </div>

            <BorderSettings v-model="itemStyle" collapsible />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import { debounce } from 'lodash'
import FontSelect from '../../widgets/font-select.vue'
import ColorPicker from '../ColorPicker.vue'
import BorderSettings from '../../widgets/BorderSettings.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'

export default defineComponent({
    name: 'StandardInstruction',
    components: {
        AdvancedOptionsToggleButton,
        ColorPicker,
        VueEditor,
        FontSelect,
        BorderSettings,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showAdvancedOptions: false,
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
            },
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        itemStyle: {
            get() {
                return this.itemData.style
            },
            set(payloads) {
                this.itemData.style = {
                    ...this.itemData.style,
                    ...payloads,
                }
                this.updateData()
            },
        },
        instructionInput: {
            get() {
                return this.itemData.content
            },
            set(value) {
                this.itemData.content = value
                this.updateData()
            },
        },
        instruction_visible: {
            get() {
                return this.itemData.visible
            },
            set(value) {
                this.itemData.visible = value
                this.updateData()
            },
        },
    },
    watch: {
        'itemStyle.font_size': {
            deep: true,
            handler: function () {
                this.$nextTick(() => {
                    this.fontSizeUpdated()
                })
            },
        },
    },
    mounted() {
        this.$refs[`widget_${this.index}`]?.quill.focus()
        let el = this.$refs[`widget_${this.index}`]?.quill.clipboard.quill.root
        let range = document.createRange()
        let sel = window.getSelection()
        if (range) {
            range.setStart(el, 1)
            range.collapse(true)
            if (sel) {
                sel.removeAllRanges()
                sel.addRange(range)
            }
        }
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
        updateFont({ font }) {
            this.itemStyle.font = font
            this.updateData()
        },
        fontSizeUpdated: debounce(function () {
            this.updateData()
        }, 500),
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';
.subtitle {
    font-size: $font-size-sm;
}
.instruction-input {
    .toggle-btn {
        right: 30px;
        top: 5px;
        svg {
            width: 16px;
        }
    }
}
</style>
