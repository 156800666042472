type TextCaseType = 'capitalize' | 'uppercase' | 'lowercase' | 'none'

type TextCaseOption = {
    value: TextCaseType
    text: string
}

export type TextCaseOptions = TextCaseOption[]

export const textCaseOptions: TextCaseOptions = [
    { value: 'capitalize', text: 'Title Case' },
    { value: 'uppercase', text: 'UPPERCASE' },
    { value: 'lowercase', text: 'lowercase' },
    { value: 'none', text: 'As entered' },
]
