<template>
    <div>
        <fieldset class="fieldset my-2">
            <legend>SETTINGS</legend>
        </fieldset>
        <div class="row">
            <div class="col-6">
                <div class="d-flex flex-column">
                    <label for="word-scramble-answer-type" class="font-weight-bold mb-1 f-11 text-muted">Answer Type</label>
                    <b-form-select
                        id="word-scramble-answer-type"
                        v-model="localData.type"
                        label="Body Font"
                        :options="answerOptions"
                        size="sm"
                        class="mt-0 mb-2"
                    />
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex flex-column">
                    <label for="word-scramble-text-case" class="font-weight-bold mb-1 f-11 text-muted">Text Case</label>
                    <b-form-select
                        id="word-scramble-text-case"
                        v-model="localData.text_case"
                        :options="textCaseOptions"
                        size="sm"
                        class="mt-0 mb-2"
                    ></b-form-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AnswerChoiceLegacy, WordScrambleData } from '../../types/word-scramble'
import { WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM } from '../../store/helpers/documentHelpers'
import { answerOptions } from '../../objects/AnswerOptions'
import { textCaseOptions, TextCaseOptions } from '../../objects/TextCaseOptions'

interface Data {
    localData: WordScrambleData
    answerOptions: { value: number; text: string }[]
    textCaseOptions: TextCaseOptions
    multipleChoices?: AnswerChoiceLegacy[]
    WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM: number
}

export default defineComponent({
    name: 'WordScrambleSettings',
    props: {
        value: {
            type: Object as PropType<WordScrambleData>,
            required: true,
        },
    },
    data(): Data {
        return {
            localData: { ...this.value },
            multipleChoices: this.value.choices,
            answerOptions,
            textCaseOptions,
            WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
        }
    },
    computed: {
        getRightChoice() {
            return {
                answer: this.localData.term,
                correct: true,
            }
        },
    },
    watch: {
        'localData.type': {
            handler() {
                this.updateData()
            },
        },
        'localData.text_case': {
            handler() {
                this.updateData()
            },
        },
    },
    methods: {
        updateData() {
            this.$emit('input', this.localData)
        },
    },
})
</script>
