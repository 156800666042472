<template>
    <div class="p-2">
        <div class="card-body p-0 pl-2 py-2 d-flex align-items-center justify-content-between mb-2">
            <StartOverButton @startOver="onClearAll" />
            <div class="d-flex align-items-center mr-2">
                <label class="ml-auto mb-0 f-12 mr-2 cursor-pointer" for="show-all-prompt">
                    Add question/prompt to Call List
                </label>

                <b-form-checkbox
                    id="show-all-prompt"
                    v-model="showAllPrompt"
                    name="options"
                    class="f-14 text-muted ml-1 mr-n2"
                    :value="1"
                    :unchecked-value="0"
                    @input="updateData()"
                ></b-form-checkbox>
            </div>
        </div>

        <div class="card-body p-2">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <span></span>
                <b-button
                    v-show="false"
                    v-b-tooltip.hover
                    title="Upload CSV"
                    class="text-nowrap overflow-visible"
                    size="sm"
                    variant="success"
                >
                    Upload CSV
                </b-button>
            </div>
            <BingoWordItems v-model="documentItems" :prompt="all_prompts" @input="updateData()"></BingoWordItems>
        </div>

        <AdvancedOptionsToggleButton :active="showAdvancedOptions" @click="showAdvancedOptions = !showAdvancedOptions" />

        <div v-if="showAdvancedOptions" class="card-body mt-2 p-2">
            <fieldset class="fieldset my-2">
                <legend>TEXT</legend>
            </fieldset>
            <div class="row w-100 m-0 my-2 align-items-baseline">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="font-body"
                            :key="'title-font' + style.font"
                            class="mt-0 mb-0 pb-0 w-100"
                            field="body_font"
                            size="sm"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <ColorPicker v-model="style.color" @change="updateColor" />
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <div class="font-weight-bold text-nowrap">Font Size</div>
                        <div class="text-secondary">
                            {{ parseInt(style.font_size) }}
                        </div>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            class="flex-glow-1"
                            type="range"
                            min="12"
                            max="64"
                            :value="style.font_size"
                            @input="
                                $store.dispatch('document/setDocumentStyle', {
                                    font_size: Number($event),
                                })
                            "
                            @change="$store.dispatch('document/storeDocumentState')"
                        ></b-form-input>
                    </div>
                </div>
            </div>
            <fieldset class="fieldset mb-2 mt-4">
                <legend>PLACEMENT</legend>
            </fieldset>
            <div class="row w-100 m-0 my-2 align-items-center">
                <div class="col p-0">
                    <div v-if="showReuseWords">
                        <label for="reuse_terms" class="font-weight-bold mb-0 f-11 text-muted">Reuse words</label>
                        <b-form-checkbox
                            id="reuse_terms"
                            v-model="reuse_terms"
                            class="f-14"
                            name="reuse_terms"
                            :unchecked-value="0"
                            :value="1"
                            switch
                        />
                    </div>
                </div>
                <div class="col p-0 text-right">
                    <button class="btn btn-sm btn-primary mt-2" @click="shuffleWords()">Shuffle</button>
                </div>
            </div>
            <div v-if="showInlineImagesManager" class="mt-4 mb-1 images">
                <fieldset class="fieldset">
                    <legend>IMAGES</legend>
                </fieldset>
                <InlineImagesManager
                    show-text-placement-setting
                    :images-settings="inlineImageSettings"
                    @change="updateImageSettings"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import BingoWordItems from '../BingoWordItems.vue'
import wmAlert from '../../helpers/wmAlerts'
import InlineImagesManager from '../../widgets/inline-images-manager.vue'
import InlineImagesMixin from '../../mixins/InlineImages'
import FontSelect from '../../widgets/font-select.vue'
import StartOverButton from '../StartOverButton.vue'
import ColorPicker from '../ColorPicker.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'

export default defineComponent({
    name: 'BingoWords',
    components: {
        AdvancedOptionsToggleButton,
        StartOverButton,
        ColorPicker,
        BingoWordItems,
        InlineImagesManager,
        FontSelect,
    },
    mixins: [InlineImagesMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
            all_prompts: 0,
            showAdvancedOptions: false,
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            items: 'document/documentItems',
            style: 'document/documentStyle',
        }),
        documentItems: {
            get() {
                return this.items
            },
            set(values) {
                this.$store.dispatch('document/dragSortItems', values)
            },
        },
        showAllPrompt: {
            get() {
                return this.all_prompts
            },
            set(value) {
                this.items.forEach((i) => (i.prompt = value))
                this.all_prompts = value
            },
        },
        inlineImageSettings() {
            return this.style?.images ?? undefined
        },
        reuse_terms: {
            get() {
                return this.document.bingo.reuse_terms
            },
            set(value) {
                this.$store.dispatch('document/setBingo', {
                    reuse_terms: Number(value),
                })
                this.updateData()
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        showReuseWords() {
            const totalTerms = this.items.length
            return totalTerms < this.document.bingo.dimension ** 2 - this.document.bingo.free_spaces.length
        },
    },
    mounted() {
        this.all_prompts = this.items.every((item) => item.prompt) ? 1 : 0
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
        onClearAll() {
            wmAlert
                .confirm({
                    html: 'This will permanently delete all words below and cannot be undone.',
                    confirmButtonText: 'Clear All',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        // clear words definitions
                        this.all_prompts = 0
                        this.$store.dispatch('document/clearItems')
                        this.updateData()
                    }
                })
        },
        async updateImageSettings(settings) {
            try {
                await this.$store.dispatch('document/setBingoImageSettings', settings)
                await this.$store.dispatch('document/storeDocumentState')
            } catch (error) {
                console.error('Bingo: Failed to update image settings: ', error)
            }
        },
        async updateColor() {
            await this.$store.dispatch('document/setDocumentStyle', {
                color: this.style.color,
            })
            await this.$store.dispatch('document/storeDocumentState')
        },
        async shuffleWords() {
            await this.$store.dispatch('document/setBingo', {
                shuffle_seed: Math.floor(Math.random() * 9999),
            })
            this.updateData()
            await this.$store.dispatch('document/storeDocumentState')
        },
    },
})
</script>

<style scoped>
.overflow-visible {
    overflow: unset;
}
</style>
