<template>
    <span v-b-tooltip.noninteractive.hover title="Numbering Options">
        <button
            v-if="showButton"
            :id="id"
            :class="btnClass"
            class="btn btn-numbering d-flex justify-content-center align-items-center rounded-sm text-sm"
            tabindex="-1"
            @click.stop="handleClick"
        >
            <NumberFormatIndex v-if="hasNumberFormat" :index-info="indexInfo" />
            <NumberFormatNoneDisplay v-else />
        </button>
        <slot></slot>
    </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Numbering } from '../types/item'
import NumberFormatIndex from './format-helpers/NumberFormatIndex.vue'
import NumberFormatNoneDisplay from '../widgets/item-manager/components/NumberFormatNoneDisplay.vue'

export default defineComponent({
    name: 'NumberingButton',
    components: {
        NumberFormatIndex,
        NumberFormatNoneDisplay,
    },
    props: {
        indexInfo: {
            type: Object as PropType<Numbering>,
            required: true,
        },
        hasNumberFormat: {
            type: Boolean,
            default: false,
        },
        btnClass: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        showButton: {
            type: Boolean,
            default: true,
        },
        id: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        handleClick() {
            this.$emit('click')
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';
// TODO: Move to global style Bootstrap variant
.btn-numbering {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1;

    &.active,
    &.hovered {
        border: 1px solid;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.5);
    }

    &.hovered {
        border-color: $gray-900;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.25);
    }

    &:focus {
        box-shadow: none;
    }
}
</style>
