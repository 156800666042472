<template>
    <div class="flex flex-grow-1 flex-row">
        <div class="d-flex align-items-center justify-content-between">
            <div
                class="position-relative rounded flex-grow-1 py-0 d-flex align-items-center"
                :class="screenSizeTooSmall ? 'pr-2' : 'px-2'"
            >
                <label id="folder-label" class="folder-label" for="file-name-input" aria-label="Filename">
                    <IconFile />
                </label>
                <b-input
                    id="file-name-input"
                    ref="save"
                    v-model="documentFilename"
                    class="save-document-input"
                    type="text"
                    size="sm"
                    aria-describedby="folder-label"
                    placeholder="[Worksheet Filename]"
                    :maxlength="maxLength"
                ></b-input>
            </div>
            <div>
                <button
                    class="btn btn-success btn-sm"
                    :style="`width: ${saveButtonWidth}`"
                    :disabled="isSaveButtonDisabled"
                    @click="saveDocument"
                >
                    {{ saveButtonText }}
                </button>
            </div>
        </div>
        <LastSaved v-if="screenSizeTooSmall" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { filenameFromTitle } from '../common/helpers'
import DocumentHelper from '../mixins/DocumentHelper'
import StatsigHelper from '../mixins/StatsigHelper'
import UserEvent from '../objects/UserEvent'
import IconFile from '../stories/components/icons/IconFile.vue'
import LastSaved from './LastSaved.vue'
import debounce from 'lodash/debounce'
import { BFormInput } from 'bootstrap-vue'
import type { DebouncedFunc } from 'lodash'

declare global {
    interface Window {
        copying: boolean
    }
}

interface Data {
    filename: string
    editing: boolean
    saveButtonWidth: string
}

type SaveButtonText = 'Save' | 'Saving...'

export default defineComponent({
    name: 'Save',
    components: { IconFile, LastSaved },
    mixins: [DocumentHelper, StatsigHelper],
    props: {
        screenSizeTooSmall: {
            type: Boolean,
            default: false,
        },
    },
    data(): Data {
        return {
            filename: '',
            editing: false,
            saveButtonWidth: '72px',
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            isWorksheet: 'document/isWorksheet',
            persistAction: 'document/immediatePersistAction',
            maxLength: 'document/filenameMaxLength',
            defaultDocumentTitle: 'document/defaultDocumentTitle',
        }),
        ...mapState({
            user: 'user',
            document: 'document',
            subscription: 'subscription',
        }),
        documentFilename: {
            get() {
                return this.filename
            },
            async set(value: string) {
                this.filename = value.trim().substring(0, this.maxLength)

                await this.$store.dispatch('document/setDocument', {
                    filename: this.filename,
                })

                await this.$store.dispatch('document/setDocument', {
                    filenameEdited: true,
                })

                this.$nextTick(async () => {
                    await this.$store.dispatch('document/storeDocumentState')
                })
            },
        },
        nameFromTitle() {
            const title = Boolean(this.document.title) ? this.document.title.replace(/(<([^>]+)>)/gi, '') : ''
            const titleContent = this.document.filenameEdited
                ? this.document.filename
                : title !== this.defaultDocumentTitle
                  ? title
                  : ``
            return filenameFromTitle(titleContent, this.document.entity_type).substring(0, this.maxLength)
        },
        fileAndTitleValue(): string {
            return `${this.document.title}|${this.document.filename}`
        },
        saveButtonText(): SaveButtonText {
            return this.editing ? 'Saving...' : 'Save'
        },
        isSaveButtonDisabled(): boolean {
            return this.editing
        },
        saveInputElement(): BFormInput {
            return this.$refs.save as BFormInput
        },
    },
    watch: {
        fileAndTitleValue: {
            handler() {
                this.updateFilenameFromTitle()
            },
            immediate: true,
        },
    },
    mounted() {
        this.setupInputListener()
    },
    async created() {
        this.handleCopying()
        await this.initializeFilename()
    },
    methods: {
        getSignUpMessage(): string {
            if (this.isWorksheet) {
                return 'worksheet_filename_save_button'
            }

            if (this.isFlashcard) {
                return 'flashcard_filename_save_button'
            }

            if (this.isBingo) {
                return 'bingo_filename_save_button'
            }

            return ''
        },
        async handleSaveForLoggedInUser(): Promise<void> {
            this.editing = true
            await this.$store.dispatch(this.persistAction, true).finally(() => {
                this.editing = false
            })
        },
        async promptUserLogin(): Promise<void> {
            await this.$modals.open('logIn')
            this.$modals.logIn.active_tab = 'sign_up'
        },
        async saveDocument(): Promise<void> {
            await this.$logTrackingEvent(UserEvent.SAVE_BUTTON_CLICKED, this.getSignUpMessage())

            if (this.isLoggedIn) {
                await this.handleSaveForLoggedInUser()
            } else {
                await this.promptUserLogin()
            }
        },
        handleCopying() {
            if (window.copying) {
                this.setInitialDocumentValues()
            }
        },
        async initializeFilename(): Promise<void> {
            if (this.document.filename) {
                await this.$store.dispatch('document/setDocument', {
                    filenameEdited: true,
                })

                this.filename = this.document.filename
            } else {
                this.filename = ''
            }

            await this.$store.dispatch('document/setDocument', {
                filename: this.filename,
            })
        },
        createDebouncedSaveHandler(): DebouncedFunc<() => Promise<void>> {
            return debounce(async () => {
                if (!this.isLoggedIn) return

                if (this.documentFilename?.trim() === '') {
                    this.filename = this.nameFromTitle
                }

                this.editing = true
                await this.$store.dispatch(this.persistAction, true).finally(() => {
                    this.editing = false
                })
            }, 2000)
        },
        setupInputListener() {
            const debouncedSave = this.createDebouncedSaveHandler()
            this.saveInputElement?.$el.addEventListener('keyup', debouncedSave)
        },
        updateFilenameFromTitle() {
            const title = Boolean(this.document.title) ? this.document.title.replace(/(<([^>]+)>)/gi, '') : ''
            if (this.documentFilename?.trim() !== '' || title?.trim() === this.document.filename?.trim()) {
                this.filename = this.nameFromTitle
            }
        },
    },
})
</script>

<style lang="scss" scoped>
.save-document-input {
    background-color: darken(#fff, 80%);
    color: darken(#fff, 20%);
    font-weight: 700;
    padding-left: 40px;
    border: 2px solid darken(#fff, 60%);
}
.folder-label {
    position: absolute;
    top: 4px;
    left: 16px;
}
</style>
